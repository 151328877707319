import { CHANGE_DATERANGE, CHANGE_LOCATION, CHANGE_PROJECT, RESET_FILTER } from "./actionTypes";

export const changeDateRange = (payload) => {
  return { type: CHANGE_DATERANGE, payload: payload };
};
export const changeLocation = (payload) => {
  return { type: CHANGE_LOCATION, payload: payload };
};

export const changeProject = (payload) => {
  return { type: CHANGE_PROJECT, payload: payload };
};

export const resetFilter = () => ({
  type: RESET_FILTER,
});
