// @flow
// YYYY-MM-DD
import { CHANGE_DATERANGE, CHANGE_LOCATION, CHANGE_PROJECT, RESET_FILTER } from "./actionTypes";
// const today = new Date();
// const firstDayOfMonth = new Date(
//   today.getFullYear(),
//   today.getMonth(),
//   1
// );

const today = new Date()
const firstDayOfMonth = new Date(
 "2024-01-01"
);
const INIT_STATE = {
  dateRange: [firstDayOfMonth,today],
  location: null,project:null
};

const dashboardFilter = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_DATERANGE:
      console.log("daterange", action.payload);

      return {
        ...state,
        dateRange: action.payload,
      };
    case CHANGE_LOCATION:
      console.log("location", action.payload);

      return {
        ...state,
        location: action.payload,
      };
      case CHANGE_PROJECT:
      console.log("location", action.payload);

      return {
        ...state,
        project: action.payload,
      };
    case RESET_FILTER:
      return {
        ...state,
        dateRange: null,
        location: null,
        project:null,
      };

    default:
      return state;
  }
};

export default dashboardFilter;
