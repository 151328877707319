import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";
import { lkred,lkgreen } from "../../Dashboard/styles";
import { connect } from "react-redux";
import { faultBar } from "../../Utility/dataSet";


class barchart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dateRange: props.dateRange,
      location: props.location,
      series: [],
      // faults: [
      //   {
      //     name: "Count",
      //     //categories: ['Power-failure', 'ESB-pressed', 'Meter-failure', 'PowerBoard-failure', 'Earth-leakage', 'PowerBoard-failure', 'Over-voltage', 'Under-voltage', 'No-load', 'Over-current'],
      //     data: series,
      //   },
      // ],
      options: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            columnWidth: "20pt",
          },
        },
      dataLabels: {
      enabled: true,
      enabledOnSeries: undefined,
      formatter: function (val, opts) {
        return val;
      },
      textAnchor: 'middle',
      distributed: false,
      offsetX: 0,
      offsetY: 0,
      style: {
          fontSize: '14px',
          fontFamily: 'Helvetica, Arial, sans-serif',
          fontWeight: 'bold',
          colors: ["#EFEFEF","FFFFFF","A1A1A1"]
      },
      
    },

        colors: [lkred,lkgreen],
        grid: {
          borderColor: "#f1f1f1",
        },
        xaxis: {
          categories: [
            "Power-failure",
            "ESB-pressed",
            "Meter-failure",
            "PowerBoard-failure",
            "Earth-leakage",
            "Over-temperature",
            "Over-voltage",
            "Under-voltage",
            "No-load",
            "Over-current",
          ],
        },
      },
    };
  }
  filterSeriesOnDate = () => {
        const currentDateRange = this.props.dateRange;
        const startDate = new Date(currentDateRange[0]);
        const endDate = new Date(currentDateRange[1]);
        const filteredData = faultBar(startDate, endDate);
        // Update state with filtered series
        this.setState({ series: filteredData });
      };
    
      componentDidMount() {
        this.filterSeriesOnDate();
      }
    
      componentDidUpdate(prevProps) {
        const prevDateRange = prevProps.dateRange;
        const currentDateRange = this.props.dateRange;
    
        // Compare the actual date values
        const prevYear1 = prevDateRange ? prevDateRange[0]?.getTime() : null;
        const prevYear2 = prevDateRange ? prevDateRange[1]?.getTime() : null;
        const currentYear1 = currentDateRange
          ? currentDateRange[0]?.getTime()
          : null;
        const currentYear2 = currentDateRange
          ? currentDateRange[1]?.getTime()
          : null;
    
        // Check if the date range or location has changed
        if (
          prevYear1 !== currentYear1 ||
          prevYear2 !== currentYear2 ||
          prevProps.location !== this.props.location
        ) {
          this.setState({
            dateRange: currentDateRange,
            location: this.props.location,
          });
    
          this.filterSeriesOnDate();
        }
      }
  render() {
    return (
      <React.Fragment>
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="bar"
          height="351"
        />
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  dateRange: state.dashboardFilter.dateRange,
  location: state.dashboardFilter.location,
});

export default connect(mapStateToProps)(barchart);
