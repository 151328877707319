import React, { Component } from "react";
import { Row, Col, Card, CardBody, ButtonGroup, Button } from "reactstrap";

//Import Charts
import ReactApexChart from "react-apexcharts";
import "./dashboard.scss";
import { lkprimary, lkgreen } from "./styles";
import { connect } from "react-redux";
import { revenueAnalytics } from "../Utility/dataSet";

class RevenueAnalytics extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dateRange: props.dateRange,
      location: props.location,
      series: [],


      options: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        stroke: {
          width: [0, 3],
          curve: "smooth",
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "20pt",
          },
        },
        dataLabels: {
          enabled: false,
        },

        legend: {
          show: false,
        },
        colors: [lkprimary, lkgreen],
        labels: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
    };
  }

  filterSeriesOnDate = () => {
    const currentDateRange = this.props.dateRange;
    console.log("currentDateRange: 0",currentDateRange);
    
    const startDate = new Date(currentDateRange[0]);
    const endDate = new Date(currentDateRange[1]);
    const filteredData = revenueAnalytics(startDate, endDate);
    // Update state with filtered series
    this.setState({ series: filteredData });
  };

  componentDidMount() {
    this.filterSeriesOnDate();
  }

  componentDidUpdate(prevProps) {
    const prevDateRange = prevProps.dateRange;
    const currentDateRange = this.props.dateRange;

    // Compare the actual date values
    const prevYear1 = prevDateRange ? prevDateRange[0]?.getTime() : null;
    const prevYear2 = prevDateRange ? prevDateRange[1]?.getTime() : null;
    const currentYear1 = currentDateRange
      ? currentDateRange[0]?.getTime()
      : null;
    const currentYear2 = currentDateRange
      ? currentDateRange[1]?.getTime()
      : null;

    // Check if the date range or location has changed
    if (
      prevYear1 !== currentYear1 ||
      prevYear2 !== currentYear2 ||
      prevProps.location !== this.props.location
    ) {
      this.setState({
        dateRange: currentDateRange,
        location: this.props.location,
      });

      this.filterSeriesOnDate();
    }
  }

  render() {
    return (
      <React.Fragment>
        <Card>
          <CardBody>
            {/* <div className="float-right d-none d-md-inline-block">
              <ButtonGroup className="mb-2">
                <Button size="sm" color="light" type="button">
                  Today
                </Button>
                <Button size="sm" color="light" type="button">
                  Weekly
                </Button>
                <Button size="sm" color="light" active type="button">
                  Monthly
                </Button>
              </ButtonGroup>
            </div> */}
            <h4 className="card-title mb-4">Revenue Analytics</h4>
            <div>
              <div id="line-column-chart" className="apex-charts" dir="ltr">
                <ReactApexChart
                  options={this.state.options}
                  series={this.state.series}
                  type="line"
                  height="280"
                />
              </div>
            </div>
          </CardBody>

          <CardBody className="border-top text-center">
            <Row>
              <Col sm={4}>
                <div className="d-inline-flex">
                  <h5 className="mr-2">₹ 15,450</h5>
                  <div className="text-danger">
                    <i className="mdi mdi-menu-down font-size-14"> </i>3.43%
                  </div>
                </div>
                <p className="text-muted text-truncate mb-0">This month</p>
              </Col>

              <Col sm={4}>
                <div className="mt-4 mt-sm-0">
                  <p className="mb-2 text-muted text-truncate">
                    <i className="mdi mdi-circle text-primary font-size-10 mr-1"></i>{" "}
                    This Year :
                  </p>
                  <div className="d-inline-flex">
                    <h5 className="mb-0 mr-2">₹ 30,950</h5>
                    <div className="text-success">
                      <i className="mdi mdi-menu-up font-size-14"> </i>10.53%
                    </div>
                  </div>
                </div>
              </Col>
              <Col sm={4}>
                <div className="mt-4 mt-sm-0">
                  <p className="mb-2 text-muted text-truncate">
                    <i className="mdi mdi-circle text-success font-size-10 mr-1"></i>{" "}
                    Previous Year :
                  </p>
                  <div className="d-inline-flex">
                    <h5 className="mb-0">₹ 1,85,914</h5>
                  </div>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  dateRange: state.dashboardFilter.dateRange,
  location: state.dashboardFilter.location,
});

export default connect(mapStateToProps)(RevenueAnalytics);
