import React, { Component } from "react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withNamespaces } from "react-i18next";

import { connect } from "react-redux";
import {
  changeLayout,
  changeLayoutWidth,
  changeSidebarTheme,
  changeSidebarType,
  changePreloader,
} from "../../store/actions";

import { isTechnician } from "../../pages/Authentication/userMetaData";

class SidebarContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: props.user,
    };
  }

  componentDidMount() {
    this.initMenu();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.props.type !== prevProps.type) {
        this.initMenu();
      }
    }
  }

  initMenu() {
    new MetisMenu("#side-menu");

    var matchingMenuItem = null;
    var ul = document.getElementById("side-menu");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  activateParentDropdown = (item) => {
    item.classList.add("active");
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show");

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add("mm-active");
          }
        }
      }
      return false;
    }
    return false;
  };

  render() {
    return (
      <React.Fragment>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{this.props.t("Menu")}</li>

            {/*<li>
              <Link to="/cms-customers" className="waves-effect">
                <i className="ri-user-search-line"></i>
                <span className="badge badge-pill badge-success float-right"></span>
                <span className="ml-1">{this.props.t("Customers")}</span>
              </Link>
            </li>*/}

            <li>
              <Link to="/dashboard" className="waves-effect">
                <i className="ri-dashboard-line"></i>
                <span className="badge badge-pill badge-success float-right">
                  3
                </span>
                <span className="ml-1">{this.props.t("Dashboard")}</span>
              </Link>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ri-charging-pile-2-fill"></i>
                <span className="ml-1">{this.props.t("Chargers")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="cms-chargers">{this.props.t("Chargers")}</Link>
                </li>

                {!isTechnician(this.state.user) && (
                  <li>
                    <Link to="cms-add-chargers">
                      {this.props.t("Add Charger")}
                    </Link>
                  </li>
                )}

                {isTechnician(this.state.user) && (
                  <li>
                    <Link to="cms-firmware-management">
                      {this.props.t("Advanced")}
                    </Link>
                  </li>
                )}
              </ul>
            </li>

            {!isTechnician(this.state.user) && (
              <li>
                <Link to="cms-transactions">
                  <i class="ri-stack-fill"></i>
                  <span className="ml-1">{this.props.t("Transactions")}</span>
                </Link>
              </li>
            )}

            {!isTechnician(this.state.user) && (
              <li>
                <Link to="cms-users">
                  <i class="ri-user-line"></i>
                  <span className="ml-1">{this.props.t("Users")}</span>
                </Link>
              </li>
            )}

            {/* <li><Link to="cms-user-details">{this.props.t('User Details')}</Link></li> */}
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ri-map-pin-line"></i>
                <span className="ml-1">{this.props.t("Locations")}</span>
              </Link>

              <ul className="sub-menu" aria-expanded="false">
                {!isTechnician(this.state.user) && (
                  <li>
                    <Link to="cms-locations">{this.props.t("Local")}</Link>
                  </li>
                )}
                {/* <li><Link to="cms-charger-detail">{this.props.t('Charger Details')}</Link></li> */}

                {/* <li><Link to="cms-user-details">{this.props.t('User Details')}</Link></li> */}
                <li>
                  <Link to="cms-multi-locations">
                    {this.props.t("Multi-regional")}
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <Link
                to={{
                  pathname: "/customer-care",
                }}
                className="waves-effect"
              >
                <i className="ri-customer-service-2-line"></i>

                <span className="ml-1">{this.props.t("Customer Care")}</span>
              </Link>
            </li>

            {!isTechnician(this.state.user) && (
              <li>
                <Link
                  to={{
                    pathname: "https://dashboard.razorpay.com/app/dashboard",
                  }}
                  target="_blank"
                  className="waves-effect"
                >
                  <i className="ri-bill-line"></i>
                  <span className="badge badge-pill badge-success float-right"></span>
                  <span className="ml-1">
                    {this.props.t("Accounts and Billing")}
                  </span>
                </Link>
              </li>
            )}
          </ul>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  //return { ...state.Layout };
  return {
    Layout: state.Layout,
    user: state.Login.user,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    changeLayout,
    changeSidebarTheme,
    changeSidebarType,
    changeLayoutWidth,
    changePreloader,
  })(withNamespaces()(SidebarContent))
);
