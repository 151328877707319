// MM-DD-YYYY

export const revenueAnalytics = (startDate, endDate) => {
  let series = [
    {
      name: "2025",
      date: "02-12-2025",
      type: "column",
      data: [15500, 15450, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
    {
      name: "2024",
      date: "09-28-2024",
      type: "column",
      data: [
        12000, 16000, 15000, 10000, 19000, 15000, 20000, 15500, 17000, 16000,
        20000, 10414,
      ],
    },
  ];

  const startYear = startDate.getFullYear();
  const endYear = endDate.getFullYear();

  const filteredData = series.filter((series) => {
    const getDate = Number(series?.name);
    return getDate >= startYear && getDate <= endYear;
  });

  return filteredData;
};

export const salesAnalytics = (startDate, endDate) => {
  let series = [
    {
      name: "2025",
      date: "02-01-2025",
      data: [45, 35, 20],
    },
    {
      name: "2024",
      date: "01-28-2025",
      data: [25, 60, 15],
    },
  ];

  const startYear = startDate.getFullYear();
  const endYear = endDate.getFullYear();

  const filteredData = series.filter((series) => {
    const getDate = Number(series?.name);
    return getDate >= startYear && getDate <= endYear;
  });


  return filteredData[0]?.data;
};

export const faultBar = (startDate, endDate) => {
  let series = [
    {
      name: "2024",
      date: "02-01-2024",
      data: [30, 43, 20, 5, 5, 3, 10, 2, 18, 0],
    },
    {
      name: "2025",
      date: "01-28-2025",
      data: [30, 33, 0, 0, 0, 0, 0, 0, 0, 0],
    },
  ];

  const startYear = startDate.getFullYear();
  const endYear = endDate.getFullYear();

  const filteredData = series.filter((series) => {
    const getDate = Number(series?.name);
    return getDate >= startYear && getDate <= endYear;
  });

  return filteredData;
};

export const activeKnob = (startDate, endDate) => {
  let series = [
    {
      name: "2024",
      date: "02-01-2024",
      data: [66, 33],
    },
    {
      name: "2025",
      date: "01-28-2025",
      data: [80, 20],
    },
  ];

  const startYear = startDate.getFullYear();
  const endYear = endDate.getFullYear();

  const filteredData = series.filter((series) => {
    const getDate = Number(series?.name);
    return getDate >= startYear && getDate <= endYear;
  });



  return filteredData[0]?.data;
};
