// Component Name:  Dropdown component
// Author: Lavanya S
// Platform:
// OS: Windows
// IDE: Visual Studio Code
// Reference: Original
// Date: 29-01-2025
// Description:  This is an reusable dropdown component
// Props: 1. Options: to display the dropdown options
//  2.onSelect: is a function that defines what should happen on Click of the Option
//  3.selectedOption: To highlight the selected option
//  4.closeDropdown: is function defines what should happen when user click outside the dropdown,
//  usually used to close the dropdown the user clicks outside the dropdown
// Dependencies: React library
// Interfaces: props
// Maintenance History: 1.0 First Version

import React, { useState, useEffect, useRef } from "react";

const DropDown = ({
  options,
  onSelect,
  selectedOption,
  closeDropDown = () => {},
}) => {
  const [selected, setSelected] = useState(null);
  useEffect(() => {
    setSelected(selectedOption);
  }, [selectedOption]);

  const [hoveredIndex, setHoveredIndex] = useState(null); // State to track hovered option
  const dropdownRef = useRef(null); // Ref to track the dropdown container

  // Handle option selection
  const handleSelect = (option) => {
    // setSelected(option);
    onSelect(option);
  };

  const handleHover = (index) => {
    setHoveredIndex(index);
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeDropDown();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <ul style={styles.dropdownList} ref={dropdownRef}>
        {options.map((option, index) => {
          const { name } = option;
          return (
            <li
              key={index}
              onClick={() => handleSelect(option)}
              onMouseEnter={() => handleHover(index)}
              onMouseLeave={() => setHoveredIndex(null)}
              style={{
                ...styles.option,
                backgroundColor:
                  selected?.name === name
                    ? "#005C8E"
                    : hoveredIndex === index && "#A2A2A2",
                color:
                  selected?.name === name
                    ? "#FFFFFF"
                    : hoveredIndex === index && "#EEEEEE",
              }}
            >
              <i className={option?.icon}></i> {name}
            </li>
          );
        })}
      </ul>
    </>
  );
};

const styles = {
  dropdownContainer: {
    border: `1.5pt solid #008CD7`,
    position: "absolute",
    top: "120px",
    right: "126px",
    padding: "10px 0 10px",
    backgroundColor: "white",
    borderRadius: "4px",
    width: "200px",
  },
  container: {
    position: "relative",
    width: "200px",
  },
  button: {
    width: "100%",
    padding: "10px",
    fontSize: "16px",
    borderRadius: "4px",
    cursor: "pointer",
    border: "1px solid #ccc",
    textAlign: "left",
  },
  dropdownList: {
    margin: 0,
    padding: 0,
    listStyleType: "none",
    backgroundColor: "#fff",
    border: "1px solid #ccc",
    boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
    zIndex: 10,
    border: `1.5pt solid #008CD7`,
    borderRadius: "4px",
  },
  option: {
    padding: "10px",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
  },
};

export default DropDown;
