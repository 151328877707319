// InvoiceComponent.js
import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  PDFDownloadLink,
} from "@react-pdf/renderer";

// Sample logo URL, replace with your logo URL or import a local image
const logoUrl = "https://example.com/logo.png";

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: 30,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 20,
  },
  logo: {
    width: 100,
    height: 100,
  },
  title: {
    fontSize: 24,
    marginBottom: 20,
  },
  section: {
    marginBottom: 10,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#bfbfbf",
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCol: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#bfbfbf",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
  },
  tableCell: {
    margin: "auto",
    marginTop: 5,
    fontSize: 10,
  },
});

// Create Document Component
const InvoiceDocument = ({ invoice }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.header}>
        <Image
          style={styles.logo}
          src={require("../assets/images/LK_Digital Logo.png")}
        />
        <Text>Invoice</Text>
      </View>
      <View style={styles.section}>
        <Text style={styles.title}>Invoice Details</Text>
        <Text>Invoice Number: {invoice.number}</Text>
        <Text>Date: {invoice.date}</Text>
        <Text>Due Date: {invoice.dueDate}</Text>
      </View>
      <View style={styles.section}>
        <Text style={styles.title}>Billing To:</Text>
        <Text>{invoice.clientName}</Text>
        <Text>{invoice.clientAddress}</Text>
      </View>
      <View style={styles.section}>
        <Text style={styles.title}>Items</Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Item</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Description</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Quantity</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Price</Text>
            </View>
          </View>
          {invoice.items.map((item, index) => (
            <View style={styles.tableRow} key={index}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{item.name}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{item.description}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{item.quantity}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>${item.price.toFixed(2)}</Text>
              </View>
            </View>
          ))}
        </View>
      </View>
      <View style={styles.section}>
        <Text>Total: ${invoice.total.toFixed(2)}</Text>
      </View>
    </Page>
  </Document>
);

const InvoiceComponent = () => {
  // Sample invoice data
  const invoice = {
    number: "12345",
    date: "2024-05-23",
    dueDate: "2024-06-23",
    clientName: "John Doe",
    clientAddress: "1234 Main St, Anytown, USA",
    items: [
      {
        name: "Item 1",
        description: "Description for item 1",
        quantity: 2,
        price: 10.0,
      },
      {
        name: "Item 2",
        description: "Description for item 2",
        quantity: 1,
        price: 15.0,
      },
    ],
    total: 35.0,
  };

  return (
    <div>
      <PDFDownloadLink
        document={<InvoiceDocument invoice={invoice} />}
        fileName="invoice.pdf"
      >
        {({ blob, url, loading, error }) =>
          loading ? "Loading document..." : "Download Invoice"
        }
      </PDFDownloadLink>
    </div>
  );
};

export default InvoiceComponent;
