import React, { Component } from "react";
import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  UncontrolledTooltip,
  Input,
  Label,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";

import { MDBDataTable } from "mdbreact";
import "./datatables.scss";

import { generatePdf } from "../../helpers/invoice";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import InvoiceComponent from "../../helpers/invoiceDocument";
import axios from "axios";

class Orders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Charger Management System", link: "#" },
        { title: "Transactions", link: "#" },
      ],
      activeTab: "1",
      transLog: [],
      userId: "9847040400",
      syncIntervalId: "",
    };
    this.toggleTab = this.toggleTab.bind(this);
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  computeTime = (isodate) => {
    if (isodate == null) return "";

    const date = new Date(isodate);

    let hour = date.getHours().toString();
    let mins = date.getMinutes().toString();
    let secs = date.getSeconds().toString();

    if (hour.length < 2) hour = "0" + hour;

    if (mins.length < 2) mins = "0" + mins;

    if (secs.length < 2) secs = "0" + secs;

    return hour + ":" + mins + ":" + secs;
  };

  computeDate = (isodate) => {
    if (isodate == null) return "";

    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const date = new Date(isodate);
    let day = date.getDate();
    let month = date.getMonth();
    let year = date.getFullYear().toString();

    if (day < 10) day = "0" + day.toString();

    if (month < 10) month = "0" + month.toString();

    return day + " " + months.at(month) + ", " + year;
  };

  computeConsumption = (starMeterVal, stopMeterVal) => {
    if (isNaN(starMeterVal)) return "";

    if (isNaN(stopMeterVal))
      return (
        <div className="badge badge-soft-warning font-size-12">On-going</div>
      );

    return (
      (parseInt(stopMeterVal, 10) - parseInt(starMeterVal, 10)) /
      1000
    ).toString();
  };

  computeTotal = (starMeterVal, stopMeterVal) => {
    if (isNaN(stopMeterVal))
      return (
        <div className="badge badge-soft-warning font-size-12">On-going</div>
      );

    return (
      "\u20B9" +
      (
        ((parseInt(stopMeterVal, 10) - parseInt(starMeterVal, 10)) * 10) /
        1000
      ).toString()
    );
  };

  computePaymentStatus = (starMeterVal, stopMeterVal) => {
    if (isNaN(stopMeterVal))
      return <div className="badge badge-soft-warning font-size-12">Paid</div>;

    return <div className="badge badge-soft-success font-size-12">Paid</div>;
  };

  async componentDidMount() {
    /* if (this.props.selectedChargerId != null) {
            if (this.state.chargerId !== this.props.selectedChargerId) {
                this.setState({ chargerId: this.props.selectedChargerId, transLog: [] });


                console.log("Update ChargerId", this.state.chargerId);
            }
        } */
    document
      .getElementsByClassName("pagination")[0]
      .classList.add("pagination-rounded");

    try {
      //await this.updateUserDetails(this.state.userId);

      const charger_status = await this.updateUserTransactions(
        this.state.userId
      );
    } catch (e) {
      console.log("Charger Details Update Error", e.toString());
    }

    const intervalId = setInterval(async () => {
      try {
        //console.log("Charger Update Routine");

        await this.updateUserTransactions(this.state.userId);
      } catch (e) {
        console.log("Charger Details Update Error", e.toString());
      }
    }, 5000);

    this.setState({ syncIntervalId: intervalId });

    /* socket = io("https://backend.iqlabs.io:3000");


        socket.on("connect", () => {
            console.log("Socket Connection Successful");

            console.log("Response Topic", this.getResponseTopic(this.state.chargerId))

            socket.emit("subscribe", { topic: this.getResponseTopic(this.state.chargerId) });

            socket.on("mqtt", msg => {

                if (msg == null)
                    return;

                //console.log("Rx Msg", msg);
                const msgJson = JSON.parse(msg);
                const topic = msgJson.topic;
                const payload = JSON.parse(msgJson.payload);

                this.processMsg(payload);


            });

        });

        this.updateChargerImage(); */
  }

  async componentWillUnmount() {
    /*  console.log("Charger Page Exited");
 
         clearInterval(this.state.syncIntervalId);
 
         socket.emit("unsubscribe", { topic: this.getResponseTopic(this.state.chargerId) });
 
         socket.disconnect(); */

    this.setState({ transLog: [] });
  }

  updateUserTransactions = async (userId) => {
    if (userId == null) return;

    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      /*    body: JSON.stringify({
                   token: "8lQzN922rpRDaMbh3m5J",
                   id: "#"
               }) */
    };
    const response = await fetch(
      "https://backend.iqlabs.io/api/evChargers/transactions/byOwnerId/?token=8lQzN922rpRDaMbh3m5J&ownerId=9847040400&limit=200&page=1",

      requestOptions
    );
    const responseJson = await response.json();

    //console.log("Fetch Charger Details", responseJson.data[0]);
    if (responseJson.allTransactions == null) return;

    const data = responseJson.allTransactions;

    // console.log("Transaction - Fetch User Details", data);

    let trans_data = [];
    data.forEach((item) => {
      trans_data.push({
        checkbox: (
          <div className="custom-control custom-checkbox">
            <Input
              type="checkbox"
              className="custom-control-input"
              id="ordercheck1"
            />
            <Label className="custom-control-label" htmlFor="ordercheck1">
              &nbsp;
            </Label>
          </div>
        ),
        id: (
          <Link to="#" className="text-dark font-weight-bold">
            {item.chargerId}
          </Link>
        ),
        date:
          this.computeDate(item.startTime) +
          ", " +
          this.computeTime(item.startTime),
        userid: item.userId,
        transactionId: item.transactionId,
        conn: item.connectorId,
        kWh: this.computeConsumption(
          parseInt(item.meterStart, 10),
          parseInt(item.meterStop, 10)
        ),
        total: this.computeTotal(
          parseInt(item.meterStart, 10),
          parseInt(item.meterStop, 10)
        ),
        type: item.type,
        status: this.computePaymentStatus(
          parseInt(item.meterStart, 10),
          parseInt(item.meterStop, 10)
        ),
        invoice: (
          <Button
            className="btn-rounded"
            color="light"
            onClick={async () => {
                try {
                  const response = await fetch('http://localhost:4000/api/downloadInvoice?transactionId=5234123&chargerId=3WESE2345&startTime=15:47:30&endTime=15:49:09&phno=9876543211&email=nagabhushan@indigoquotient.com&serviceProvider=i-Pec India Pvt Ltd&GST=24WEDRT3459230491ZZ&address=KR Puram, Pattandur Agrahara &consumption=0.021&description=Charging Session&price=10', {
                    method: 'GET',
                    headers: {
                      'Content-Type': 'application/pdf',
                    },
                  });
            
                  if (!response.ok) {
                    throw new Error('Network response was not ok');
                  }
            
                  const blob = await response.blob();
                  const url = window.URL.createObjectURL(blob);
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute('download', 'invoice.pdf');             
                  document.body.appendChild(link);
                  link.click();
            
                  // Cleanup
                  link.remove();
                  window.URL.revokeObjectURL(url);
                } catch (error) {
                  console.error('Error downloading the file:', error);
                }
            }}
          >
            Invoice <i className="mdi mdi-download ml-2"></i>
          </Button>
          // <InvoiceComponent />
        ),
        // action: (
        //   <>
        //     <Link to="#" className="mr-3 text-primary" id="edit1">
        //       <i className="mdi mdi-pencil font-size-18"></i>
        //     </Link>
        //     <UncontrolledTooltip placement="top" target="edit1">
        //       Edit
        //     </UncontrolledTooltip>
        //     <Link to="#" className="text-danger" id="delete1">
        //       <i className="mdi mdi-trash-can font-size-18"></i>
        //     </Link>
        //     <UncontrolledTooltip placement="top" target="delete1">
        //       Delete
        //     </UncontrolledTooltip>
        //   </>
        // ),
      });
    });

    if (JSON.stringify(trans_data) === JSON.stringify(this.state.transLog))
      return;

    this.setState({ transLog: trans_data });
  };

  render() {
    const data = {
      columns: [
        // {
        //   label: (
        //     <div className="custom-control custom-checkbox">
        //       {" "}
        //       <Input
        //         type="checkbox"
        //         className="custom-control-input"
        //         id="ordercheck"
        //       />
        //       <Label className="custom-control-label" htmlFor="ordercheck">
        //         &nbsp;
        //       </Label>
        //     </div>
        //   ),
        //   field: "checkbox",
        //   sort: "asc",
        //   width: 28,
        // },
        {
          label: "Charger ID",
          field: "id",
          sort: "asc",
          width: 78,
        },
        {
          label: "Conn ID",
          field: "conn",
          sort: "asc",
          width: 28,
        },
        {
          label: "Date",
          field: "date",
          sort: "asc",
          width: 93,
        },
        {
          label: "User Id",
          field: "userid",
          sort: "asc",
          width: 109,
        },
        {
          label: "Transaction Id",
          field: "transactionId",
          sort: "asc",
          width: 90,
        },
        {
          label: "Type",
          field: "type",
          sort: "asc",
          width: 28,
        },
        {
          label: "Units",
          field: "kWh",
          sort: "asc",
          width: 28,
        },
        {
          label: "Total",
          field: "total",
          sort: "asc",
          width: 48,
        },
        {
          label: "Payment Status",
          field: "status",
          sort: "asc",
          width: 135,
        },
        {
          label: "Invoice",
          field: "invoice",
          sort: "asc",
          width: 110,
        },
        // {
        //   label: "Action",
        //   field: "action",
        //   sort: "asc",
        //   width: 120,
        // },
      ],
      rows: this.state.transLog,
      /*  rows: [
                 {
                     checkbox:
                         <div className="custom-control custom-checkbox">
                             <Input type="checkbox" className="custom-control-input" id="ordercheck1" />
                             <Label className="custom-control-label" htmlFor="ordercheck1">&nbsp;</Label>
                         </div>,
                     id: <Link to="#" className="text-dark font-weight-bold">#CF1572</Link>,
                     date: "21 Oct, 2021",
                     userid: "Ashok.Kumar",
                     conn: "1",
                     kWh: "9",
                     total: "₹ 90",
                     status: <div className="badge badge-soft-success font-size-12">Pre-paid</div>,
                     invoice: <Button className="btn-rounded" color="light">Invoice <i className="mdi mdi-download ml-2"></i></Button>,
                     action: <><Link to="#" className="mr-3 text-primary" id="edit1"><i className="mdi mdi-pencil font-size-18"></i></Link>
                         <UncontrolledTooltip placement="top" target="edit1">
                             Edit
                         </UncontrolledTooltip >
                         <Link to="#" className="text-danger" id="delete1"><i className="mdi mdi-trash-can font-size-18"></i></Link>
                         <UncontrolledTooltip placement="top" target="delete1">
                             Delete
                         </UncontrolledTooltip >
                     </>
                 },
                 {
                     checkbox:
                         <div className="custom-control custom-checkbox">
                             <Input type="checkbox" className="custom-control-input" id="ordercheck2" />
                             <Label className="custom-control-label" htmlFor="ordercheck2">&nbsp;</Label>
                         </div>,
                     id: <Link to="#" className="text-dark font-weight-bold">#CF1571</Link>,
                     date: "21 Oct, 2021",
                     userid: "Jon.Doe1",
                     total: "₹ 100",
                     conn: "2",
                     kWh: "10",
                     status: <div className="badge badge-soft-success font-size-12">Pre-paid</div>,
                     invoice: <Button className="btn-rounded" color="light">Invoice <i className="mdi mdi-download ml-2"></i></Button>,
                     action: <><Link to="#" className="mr-3 text-primary" id="edit2"><i className="mdi mdi-pencil font-size-18"></i></Link>
                         <UncontrolledTooltip placement="top" target="edit2">
                             Edit
                         </UncontrolledTooltip >
                         <Link to="#" className="text-danger" id="delete2"><i className="mdi mdi-trash-can font-size-18"></i></Link>
                         <UncontrolledTooltip placement="top" target="delete2">
                             Delete
                         </UncontrolledTooltip >
                     </>
                 },
                 {
                     checkbox:
                         <div className="custom-control custom-checkbox">
                             <Input type="checkbox" className="custom-control-input" id="ordercheck3" />
                             <Label className="custom-control-label" htmlFor="ordercheck3">&nbsp;</Label>
                         </div>,
                     id: <Link to="#" className="text-dark font-weight-bold">#CF1570</Link>,
                     date: "21 Oct, 2021",
                     userid: "JaneDoe1",
                     total: "₹ 200",
                     conn: "2",
                     kWh: "20",
                     status: <div className="badge badge-soft-success font-size-12">Pre-paid</div>,
                     invoice: <Button className="btn-rounded" color="light">Invoice <i className="mdi mdi-download ml-2"></i></Button>,
                     action: <><Link to="#" className="mr-3 text-primary" id="edit3"><i className="mdi mdi-pencil font-size-18"></i></Link>
                         <UncontrolledTooltip placement="top" target="edit3">
                             Edit
                         </UncontrolledTooltip >
                         <Link to="#" className="text-danger" id="delete3"><i className="mdi mdi-trash-can font-size-18"></i></Link>
                         <UncontrolledTooltip placement="top" target="delete3">
                             Delete
                         </UncontrolledTooltip >
                     </>
                 },
                 {
                     checkbox:
                         <div className="custom-control custom-checkbox">
                             <Input type="checkbox" className="custom-control-input" id="ordercheck4" />
                             <Label className="custom-control-label" htmlFor="ordercheck4">&nbsp;</Label>
                         </div>,
                     id: <Link to="#" className="text-dark font-weight-bold">#CF1569</Link>,
                     date: "21 Oct, 2021",
                     userid: "David.Gilmour",
                     total: "₹ 300",
                     conn: "1",
                     kWh: "15",
                     status: <div className="badge badge-soft-success font-size-12">Pre-paid</div>,
                     invoice: <Button className="btn-rounded" color="light">Invoice <i className="mdi mdi-download ml-2"></i></Button>,
                     action: <><Link to="#" className="mr-3 text-primary" id="edit4"><i className="mdi mdi-pencil font-size-18"></i></Link>
                         <UncontrolledTooltip placement="top" target="edit4">
                             Edit
                         </UncontrolledTooltip >
                         <Link to="#" className="text-danger" id="delete5"><i className="mdi mdi-trash-can font-size-18"></i></Link>
                         <UncontrolledTooltip placement="top" target="delete5">
                             Delete
                         </UncontrolledTooltip >
                     </>
                 },
                 {
                     checkbox:
                         <div className="custom-control custom-checkbox">
                             <Input type="checkbox" className="custom-control-input" id="ordercheck6" />
                             <Label className="custom-control-label" htmlFor="ordercheck6">&nbsp;</Label>
                         </div>,
                     id: <Link to="#" className="text-dark font-weight-bold">#CF1568</Link>,
                     date: "21 Oct, 2021",
                     userid: "Roger.Waters",
                     total: "₹ 250",
                     conn: "2",
                     kWh: "12",
                     status: <div className="badge badge-soft-success font-size-12">Pre-paid</div>,
                     invoice: <Button className="btn-rounded" color="light">Invoice <i className="mdi mdi-download ml-2"></i></Button>,
                     action: <><Link to="#" className="mr-3 text-primary" id="edit6"><i className="mdi mdi-pencil font-size-18"></i></Link>
                         <UncontrolledTooltip placement="top" target="edit6">
                             Edit
                         </UncontrolledTooltip >
                         <Link to="#" className="text-danger" id="delete6"><i className="mdi mdi-trash-can font-size-18"></i></Link>
                         <UncontrolledTooltip placement="top" target="delete6">
                             Delete
                         </UncontrolledTooltip >
                     </>
                 },
                 {
                     checkbox:
                         <div className="custom-control custom-checkbox">
                             <Input type="checkbox" className="custom-control-input" id="ordercheck7" />
                             <Label className="custom-control-label" htmlFor="ordercheck7">&nbsp;</Label>
                         </div>,
                     id: <Link to="#" className="text-dark font-weight-bold">#CF1567</Link>,
                     date: "21 Oct, 2021",
                     userid: "Gordon.Sumner",
                     total: "₹ 72",
                     conn: "2",
                     kWh: "7.2",
                     status: <div className="badge badge-soft-success font-size-12">Pre-paid</div>,
                     invoice: <Button className="btn-rounded" color="light">Invoice <i className="mdi mdi-download ml-2"></i></Button>,
                     action: <><Link to="#" className="mr-3 text-primary" id="edit7"><i className="mdi mdi-pencil font-size-18"></i></Link>
                         <UncontrolledTooltip placement="top" target="edit7">
                             Edit
                         </UncontrolledTooltip >
                         <Link to="#" className="text-danger" id="delete7"><i className="mdi mdi-trash-can font-size-18"></i></Link>
                         <UncontrolledTooltip placement="top" target="delete7">
                             Delete
                         </UncontrolledTooltip >
                     </>
                 },
                 {
                     checkbox:
                         <div className="custom-control custom-checkbox">
                             <Input type="checkbox" className="custom-control-input" id="ordercheck8" />
                             <Label className="custom-control-label" htmlFor="ordercheck8">&nbsp;</Label>
                         </div>,
                     id: <Link to="#" className="text-dark font-weight-bold">#CF1566</Link>,
                     date: "21 Oct, 2021",
                     userid: "Michael.Riley",
                     total: "₹ 500",
                     conn: "1",
                     kWh: "25",
                     status: <div className="badge badge-soft-success font-size-12">Pre-paid</div>,
                     invoice: <Button className="btn-rounded" color="light">Invoice <i className="mdi mdi-download ml-2"></i></Button>,
                     action: <><Link to="#" className="mr-3 text-primary" id="edit9"><i className="mdi mdi-pencil font-size-18"></i></Link>
                         <UncontrolledTooltip placement="top" target="edit9">
                             Edit
                         </UncontrolledTooltip >
                         <Link to="#" className="text-danger" id="delete9"><i className="mdi mdi-trash-can font-size-18"></i></Link>
                         <UncontrolledTooltip placement="top" target="delete9">
                             Delete
                         </UncontrolledTooltip >
                     </>
                 },
                 {
                     checkbox:
                         <div className="custom-control custom-checkbox">
                             <Input type="checkbox" className="custom-control-input" id="ordercheck10" />
                             <Label className="custom-control-label" htmlFor="ordercheck10">&nbsp;</Label>
                         </div>,
                     id: <Link to="#" className="text-dark font-weight-bold">#CF1565</Link>,
                     date: "21 Oct, 2021",
                     userid: "Ashoka.Kumari",
                     total: "₹ 20",
                     conn: "2",
                     kWh: "2",
                     status: <div className="badge badge-soft-success font-size-12">Pre-paid</div>,
                     invoice: <Button className="btn-rounded" color="light">Invoice <i className="mdi mdi-download ml-2"></i></Button>,
                     action: <><Link to="#" className="mr-3 text-primary" id="edit10"><i className="mdi mdi-pencil font-size-18"></i></Link>
                         <UncontrolledTooltip placement="top" target="edit10">
                             Edit
                         </UncontrolledTooltip >
                         <Link to="#" className="text-danger" id="delete10"><i className="mdi mdi-trash-can font-size-18"></i></Link>
                         <UncontrolledTooltip placement="top" target="delete10">
                             Delete
                         </UncontrolledTooltip >
                     </>
                 },
                 {
                     checkbox:
                         <div className="custom-control custom-checkbox">
                             <Input type="checkbox" className="custom-control-input" id="ordercheck11" />
                             <Label className="custom-control-label" htmlFor="ordercheck11">&nbsp;</Label>
                         </div>,
                     id: <Link to="#" className="text-dark font-weight-bold">#CF1564</Link>,
                     date: "21 Oct, 2021",
                     userid: "Gibbs",
                     total: "₹ 210",
                     conn: "2",
                     kWh: "21",
                     status: <div className="badge badge-soft-success font-size-12">Pre-paid</div>,
                     invoice: <Button className="btn-rounded" color="light">Invoice <i className="mdi mdi-download ml-2"></i></Button>,
                     action: <><Link to="#" className="mr-3 text-primary" id="edit11"><i className="mdi mdi-pencil font-size-18"></i></Link>
                         <UncontrolledTooltip placement="top" target="edit11">
                             Edit
                         </UncontrolledTooltip >
                         <Link to="#" className="text-danger" id="delete11"><i className="mdi mdi-trash-can font-size-18"></i></Link>
                         <UncontrolledTooltip placement="top" target="delete11">
                             Delete
                         </UncontrolledTooltip >
                     </>
                 },
                 {
                     checkbox:
                         <div className="custom-control custom-checkbox">
                             <Input type="checkbox" className="custom-control-input" id="ordercheck12" />
                             <Label className="custom-control-label" htmlFor="ordercheck12">&nbsp;</Label>
                         </div>,
                     id: <Link to="#" className="text-dark font-weight-bold">#CF1563</Link>,
                     date: "20 Oct, 2021",
                     userid: "Mbappe",
                     total: "₹ 180",
                     conn: "2",
                     kWh: "9",
                     status: <div className="badge badge-soft-success font-size-12">Pre-paid</div>,
                     invoice: <Button className="btn-rounded" color="light">Invoice <i className="mdi mdi-download ml-2"></i></Button>,
                     action: <><Link to="#" className="mr-3 text-primary" id="edit12"><i className="mdi mdi-pencil font-size-18"></i></Link>
                         <UncontrolledTooltip placement="top" target="edit12">
                             Edit
                         </UncontrolledTooltip >
                         <Link to="#" className="text-danger" id="delete12"><i className="mdi mdi-trash-can font-size-18"></i></Link>
                         <UncontrolledTooltip placement="top" target="delete12">
                             Delete
                         </UncontrolledTooltip >
                     </>
                 },
                 {
                     checkbox:
                         <div className="custom-control custom-checkbox">
                             <Input type="checkbox" className="custom-control-input" id="ordercheck13" />
                             <Label className="custom-control-label" htmlFor="ordercheck13">&nbsp;</Label>
                         </div>,
                     id: <Link to="#" className="text-dark font-weight-bold">#CF15632</Link>,
                     date: "20 Oct, 2021",
                     userid: "SRT",
                     total: "₹ 306",
                     conn: "1",
                     kWh: "15.3",
                     status: <div className="badge badge-soft-success font-size-12">Pre-paid</div>,
                     invoice: <Button className="btn-rounded" color="light">Invoice <i className="mdi mdi-download ml-2"></i></Button>,
                     action: <><Link to="#" className="mr-3 text-primary" id="edit13"><i className="mdi mdi-pencil font-size-18"></i></Link>
                         <UncontrolledTooltip placement="top" target="edit13">
                             Edit
                         </UncontrolledTooltip >
                         <Link to="#" className="text-danger" id="delete13"><i className="mdi mdi-trash-can font-size-18"></i></Link>
                         <UncontrolledTooltip placement="top" target="delete13">
                             Delete
                         </UncontrolledTooltip >
                     </>
                 }] */
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Orders"
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody className="pt-0">
                    <Nav tabs className="nav-tabs-custom mb-4">
                      <NavItem>
                        <NavLink
                          onClick={() => {
                            this.toggleTab("1");
                          }}
                          className={classnames(
                            { active: this.state.activeTab === "1" },
                            "font-weight-bold p-3"
                          )}
                        >
                          All Orders
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          onClick={() => {
                            this.toggleTab("2");
                          }}
                          className={classnames(
                            { active: this.state.activeTab === "2" },
                            "p-3 font-weight-bold"
                          )}
                        >
                          Active
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          onClick={() => {
                            this.toggleTab("3");
                          }}
                          className={classnames(
                            { active: this.state.activeTab === "3" },
                            " p-3 font-weight-bold"
                          )}
                        >
                          Unpaid
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <MDBDataTable responsive data={data} className="mt-4" />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default Orders;
