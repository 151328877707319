import React, { Component } from "react";
// import {
//   Map,
//   GoogleApiWrapper,
//   InfoWindow,
//   Marker,
//   HeatMap,
// } from "google-maps-react";
import MiniWidgets from "../Dashboard/MiniWidgets";
import { Link } from "react-router-dom";
import LightData from "../Maps/LightData";
import MarkerImg from "../../assets/images/infobar/energy.png";
import chargeIcon from "../../assets/images/infobar/clusterIcon1.png";
import companyIcon from "../../assets/images/infobar/companyIcon.png";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Container } from "reactstrap";
import { bind } from "leaflet";
import {
  GoogleMap,
  HeatmapLayer,
  LoadScript,
  Marker,
  MarkerClusterer,
} from "@react-google-maps/api";

const containerStyle = {
  width: "101.5%",
  height: "100%",
};

class Locations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      center: { lat: 28.46175, lng: 77.500485 },
      breadcrumbItems: [
        { title: "Energy Dash", link: "#" },
        { title: "Locations", link: "#" },
      ],
      sessions: 67,
      syncInterval: "",
      selectedCard: "",
      chargersDisplay: "",
      // card data
      cardData: [
        {
          key: "1",
          title: "India Expo center and Mart",
          description: " Chargers: 4",
          info: " Sessions: 67",
          lat: 28.46175,
          lng: 77.500485,
          idle: 0,
          InUse: 0,
          Faulted: 0,
          Offline: 4,
        },
        {
          key: "2",
          title: "Schneider Electric-Prima Bay",
          description: " Chargers: 0",
          info: " Sessions: 0",
          lat: 19.1239448,
          lng: 72.8933322,
          idle: 0,
          InUse: 0,
          Faulted: 0,
          Offline: 0,
        },
      ],

      isLoaded: false,
      map: null,
    };

    //style for custer marker
    this.clusterStyles = [
      {
        textColor: "#ffffff",
        url: chargeIcon, // URL to your icon image
        height: 40,
        width: 40,
      },
      {
        textColor: "#ffffff",
        url: chargeIcon,
        height: 60,
        width: 60,
      },
      {
        textColor: "#ffffff",
        url: chargeIcon,
        height: 70,
        width: 70,
      },
    ];
    this.onLoad = this.onLoad.bind(this);
    this.onUnmount = this.onUnmount.bind(this);
    this.sectionRefs = this.state.cardData.map(() => React.createRef());
  }
  // auto scroll to item onClick Marker
  scrollToSection = (index) => {
    const section = this.sectionRefs[index].current;
    if (section) {
      section.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
      // const yOffset = -100; // Adjust this value as needed
      // const y = section.getBoundingClientRect().top + window.scrollY + yOffset;
      // window.scrollTo({ top: y, behavior: "smooth" });
    }
  };
  onLoad(map) {
    this.setState({ map });
  }

  onUnmount() {
    this.setState({ map: null });
  }

  handleScriptLoad = () => {
    this.setState({ isLoaded: true });
  };

  handleScriptError = () => {
    console.error("Error loading Google Maps API");
  };
  async componentDidMount() {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      /*    body: JSON.stringify({
                   token: "8lQzN922rpRDaMbh3m5J",
                   id: "#"
               }) */
    };
    let data = 0;
    try {
      const response = await fetch(
        "https://backend.iqlabs.io/api/evChargers/transactions/byOwnerId/?token=8lQzN922rpRDaMbh3m5J&ownerId=9847040400&limit=10&page=1",

        requestOptions
      );
      const responseJson = await response.json();

      data = responseJson.totalCount;
    } catch (err) {
      console.log("Error Fetching the data : ", err);
    }

    let intervalId = setInterval(async () => {
      try {
        const response = await fetch(
          "https://backend.iqlabs.io/api/evChargers/transactions/byOwnerId/?token=8lQzN922rpRDaMbh3m5J&ownerId=9847040400&limit=10&page=1",

          {
            method: "GET",
            headers: { "Content-Type": "application/json" },
            /*    body: JSON.stringify({
                         token: "8lQzN922rpRDaMbh3m5J",
                         id: "#"
                     }) */
          }
        );
        const responseJson = await response.json();

        const data = responseJson.totalCount;

        if (data != this.state.sessions) this.setState({ sessions: data });
      } catch (err) {
        console.log("Error fetching the data: ", err);
      }
    }, 5000);

    this.setState({ sessions: data, syncInterval: intervalId });
  }

  async componentWillUnmount() {
    clearInterval(this.state.syncInterval);
  }

  render() {
    const { isLoaded } = this.state;
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Locations"
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <div style={styles.container}>
              {/* charger station list */}
              <div style={styles.cardContainer} className="col-3">
                {this.state.cardData.map((card, index) => (
                  <Link to="cms-chargers" className="text-dark">
                    <div
                      key={index}
                      style={
                        this.state.selectedCard === card.key
                          ? styles.selectedCard
                          : styles.card
                      }
                      ref={this.sectionRefs[index]}
                      onMouseOver={() =>
                        this.setState({ chargersDisplay: card.key })
                      }
                      onMouseLeave={() =>
                        this.setState({ chargersDisplay: "" })
                      }
                    >
                      <div style={styles.cardHeadingContainer}>
                        <img src={companyIcon} style={styles.companyIcon} />
                        <h5
                          style={{
                            color:
                              this.state.selectedCard === card.key
                                ? "#005C8E"
                                : this.state.chargersDisplay === card.key &&
                                  "#005C8E",
                            textDecoration:
                              this.state.chargersDisplay === card.key
                                ? "underline"
                                : "none",
                            textDecorationColor:
                              this.state.chargersDisplay === card.key
                                ? "#005C8E"
                                : "none",
                            margin: "0",
                          }}
                        >
                          {card.title}
                        </h5>
                      </div>

                      <span
                        className="d-flex align-items-center gap-8 "
                        style={{ fontSize: "17px" }}
                      >
                        <i
                          className="ri-charging-pile-2-fill "
                          style={{ color: "#7E7E7E" }}
                        ></i>
                        <span className="px-2"> {card.description}</span>
                        <i className="ri-arrow-down-s-fill"></i>
                      </span>

                      <div
                        className="pl-2"
                        style={{
                          display:
                            this.state.chargersDisplay === card.key
                              ? "block"
                              : "none",
                        }}
                      >
                        <span className="d-flex align-items-center gap-8">
                          <i
                            className="ri-checkbox-blank-circle-fill"
                            style={{ color: "#4c90f8", paddingRight: "3px" }}
                          ></i>
                          Idle: {card.idle}
                        </span>
                        <span className="d-flex align-items-center gap-4">
                          <i
                            className="ri-checkbox-circle-fill"
                            style={{ color: "#47b273", paddingRight: "3px" }}
                          ></i>
                          InUse: {card.InUse}
                        </span>
                        <span className="d-flex align-items-center gap-4">
                          <i
                            className="ri-close-circle-fill"
                            style={{ color: "#E56367", paddingRight: "3px" }}
                          ></i>
                          Faulted: {card.Faulted}
                        </span>
                        <span className="d-flex align-items-center gap-4">
                          <i
                            className="ri-cloud-off-fill"
                            style={{ color: "#A2A2A2", paddingRight: "3px" }}
                          ></i>
                          Offline: {card.Offline}
                        </span>
                      </div>
                      <span
                        className="d-flex align-items-center gap-8"
                        style={{ fontSize: "17px" }}
                      >
                        <i
                          className="ri-stack-line"
                          style={{ color: "#7E7E7E" }}
                        ></i>
                        <span className="px-2">
                          Sessions: {index === 0 ? this.state.sessions : 0}
                        </span>
                      </span>
                    </div>
                  </Link>
                ))}
              </div>
              {/* Map container */}
              <div style={styles.map} className="col-9">
                {/* <Map
                  google={this.props.google}
                  zoom={9}
                  // styles={LightData.Data}
                  // style={mapStyles}
                  initialCenter={{
                    lat: 28.5547565, // Latitude for India
                    lng: 77.043062, // Longitude for India
                  }}
                >
                  {this.state.cardData.map((card, index) => (
                    <Marker
                      key={index}
                      position={{
                        lat: card.lat,
                        lng: card.lng,
                      }}
                      title={card.title}
                      name={card.title}
                      onClick={() => {
                        this.setState({
                          selectedCard: card.key,
                        });
                        this.scrollToSection(index);
                      }}
                      // icon={MarkerImg}
                      icon={{
                        url: MarkerImg,
                        scaledSize: new window.google.maps.Size(30, 30),
                      }}
                    />
                  ))}
                </Map> */}

                <LoadScript
                  googleMapsApiKey="AIzaSyBcgqRhNZTzePlgvGoB3mgYqE_fEkL1yuA"
                  onLoad={this.handleScriptLoad}
                  onError={this.handleScriptError}
                  libraries={["visualization"]}
                >
                  {isLoaded ? (
                    <GoogleMap
                      google={this.props.google}
                      mapContainerStyle={containerStyle}
                      center={this.state.center}
                      onLoad={this.onLoad}
                      onUnmount={this.onUnmount}
                      zoom={9}
                    >
                      <MarkerClusterer
                        gridSize={10}
                        maxZoom={24}
                        styles={this.clusterStyles}
                      >
                        {(clusterer) =>
                          this.state.cardData.map((card, index) => (
                            <Marker
                              key={index}
                              position={{ lat: card.lat, lng: card.lng }}
                              title={card.title}
                              name={card.title}
                              clusterer={clusterer}
                              onClick={() => {
                                this.setState({
                                  selectedCard: card.key,
                                  center: { lat: card.lat, lng: card.lng },
                                });
                                this.scrollToSection(index);
                              }}
                              icon={{
                                url: MarkerImg,
                                scaledSize: new window.google.maps.Size(30, 30),
                              }}
                            />
                          ))
                        }
                      </MarkerClusterer>
                      {/* {this.state.map && this.heatMapData ? (
                        <>
                          <HeatmapLayer
                            data={this.heatMapData.map(
                              (data) =>
                                new window.google.maps.LatLng(
                                  data.lat,
                                  data.lng
                                )
                            )}
                            options={{ radius: 25 }}
                          />
                        </>
                      ) : (
                        console.log("no map data")
                      )} */}
                    </GoogleMap>
                  ) : (
                    <div>Loading...</div>
                  )}
                </LoadScript>
              </div>
            </div>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const styles = {
  container: {
    display: "flex",
    height: "80vh",
    //  marginTop: "100px",
  },
  cardContainer: {
    // width: "25%",
    padding: "10px",
    boxSizing: "border-box",
    backgroundColor: "#f8f9fa",
    borderRight: "1px solid #dee2e6",
    overflowY: "auto",
    // scrollbarWidth: "none",
    // scrollBehavior: "smooth",
  },
  card: {
    marginBottom: "20px",
    padding: "10px",
    backgroundColor: "#ffffff",
    boxShadow: "0 0 5px rgba(0,0,0,0.3)",
    borderRadius: "5px",
  },
  selectedCard: {
    marginBottom: "20px",
    padding: "10px",
    backgroundColor: "#efefef",
    boxShadow: "0 0 5px rgba(0,0,0,0.4)",
    borderRadius: "5px",
  },
  cardHeadingContainer: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    gap: "6px",
  },
  companyIcon: {
    width: "30px",
    height: "30px",
    borderRadius: "50%",
  },
  map: {
    // width: "75%",
    // height: "100%",
  },
};

export default Locations;
