// Component Name:  InfoBar component
// Author: Lavanya S
// Platform:
// OS: Windows
// IDE: Visual Studio Code
// Reference: Original
// Date: 17-07-2024
// Description:  This is an non-scrollable info bar component, with fixed height
// we can expand and collapse the info bar
// when expanded it act as a buttons
// when collapses it displays texts without button
// Dependencies: React library, DropDown
// Interfaces: props
// Maintenance History: 1.0 First Version
// updated to use DropDown component : 30-01-2025

import React, { useEffect, useState, useRef } from "react";
import LK_User from "../../assets/images/infobar/user.svg";
import setting from "../../assets/images/infobar/settings.png";
import bell from "../../assets/images/infobar/bell.png";
import arrowUp from "../../assets/images/infobar/arrow-up.png";
import arrowDown from "../../assets/images/infobar/arrow-down.png";
import arrowUpLight from "../../assets/images/infobar/arrow-up-light.png";
import arrowDownLight from "../../assets/images/infobar/arrow-down-light.png";
import menu1 from "../../assets/images/infobar/menu1.png";
import menu2 from "../../assets/images/infobar/menu2.png";
import expoMart from "../../assets/images/infobar/india_expo_mart.jpg";
import ReactFlatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.min.css";
import { connect } from "react-redux";
import {
  changeDateRange,
  changeLocation,
  changeProject,
} from "../../store/dbFilter/actions";

import DropDown from "./DropDown";
import {
  dashboardData,
  dateRangeData,
  locationData,
  projectData,
} from "./dbConstants";

const InfoBar = (props) => {
  const {
    toggleInfobarCallback,
    infobarType,
    dateRange,
    changeDateRange,
    changeLocation,
    changeProject,
  } = props;

  const [isExpand, setIsExpand] = useState(false);
  const [isDropdown, setIsDropdown] = useState(false);

  const [startDate, setStartDate] = useState(dateRange[0]);
  const [endDate, setEndDate] = useState(dateRange[1]);

  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);
  const calendarRef = useRef(null);
  const startRef = useRef(null);
  const endRef = useRef(null);

  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedProject, setSelectedProject] = useState("");

  const [selectedDateRange, setSelectedDateRange] = useState(dateRangeData[6]);
  const [selectedButton, setSelectedButton] = useState(null);
  console.log("selectedButton selectedButton: ",selectedButton);

  // Based on the date range type selected by the user,
  // start date and end date will be calculated
  const handleDateRange = (dateRangeType) => {
    const today = new Date();
    let start = null;
    let end = null;
    setEndDate(null);
    setStartDate(null);

    switch (dateRangeType) {
      case "today":
        start = today;
        end = today;
        break;

      case "yesterday":
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);
        start = yesterday;
        end = start;
        break;

      case "day7":
        const last7DaysStart = new Date(today);
        last7DaysStart.setDate(today.getDate() - 6);
        start = last7DaysStart;
        end = today;
        break;

      case "day30":
        const last30DaysStart = new Date(today);
        last30DaysStart.setDate(today.getDate() - 29);
        start = last30DaysStart;
        end = today;
        break;

      case "thisMonth":
        const firstDayOfMonth = new Date(
          today.getFullYear(),
          today.getMonth(),
          1
        );
        start = firstDayOfMonth;
        end = today;
        break;

      case "lastMonth":
        const firstDayOfLastMonth = new Date(
          today.getFullYear(),
          today.getMonth() - 1,
          1
        );
        const lastDayOfLastMonth = new Date(
          today.getFullYear(),
          today.getMonth(),
          0
        );
        start = firstDayOfLastMonth;
        end = lastDayOfLastMonth;
        break;

      default:
        break;
    }

    changeDateRange([start, end]);
  };

  // Close dropdown if clicked outside
  const handleClickOutside = (event) => {
    // Check if the click is outside the dropdown or button
    const startInstance = startRef.current ? startRef.current.flatpickr : null;
    const endInstance = endRef.current ? endRef.current.flatpickr : null;
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target)
    ) {
      if (calendarRef?.current) {
        if (
          !calendarRef.current.contains(event.target) &&
          startInstance &&
          startInstance.calendarContainer &&
          !startInstance.calendarContainer?.contains(event.target) &&
          endInstance &&
          endInstance.calendarContainer &&
          !endInstance.calendarContainer?.contains(event.target)
        ) {
          setIsDropdown(false);
          setSelectedButton(null);
          setSelectedOption("");
        }
      } else {
        console.log("outside clicked", selectedButton);
        console.log("selectedButton inside loop: ", selectedButton);

        setIsDropdown(false);
        setSelectedOption("");

        if (selectedButton === 8) {
          setSelectedButton(null);
        }
        setSelectedButton(null);
      }
      console.log("dropdownRef.current: ", dropdownRef.current);
    }
    //  console.log( "dropdownRef.current: ", dropdownRef.current  );
  };

  // Handle start date change
  const handleStartDateChange = (selectedDates) => {
    if (selectedDates && selectedDates.length > 0) {
      setStartDate(selectedDates[0]);
      if (endDate && endDate < selectedDates[0]) {
        setEndDate(null); // Reset end date if it's before the new start date
      }
    }
    changeDateRange([selectedDates[0], endDate]);
  };

  // Handle end date change
  const handleEndDateChange = (selectedDates) => {
    if (selectedDates && selectedDates.length > 0) {
      setEndDate(selectedDates[0]);
    }
    changeDateRange([startDate, selectedDates[0]]);
  };

  useEffect(() => {
    infobarType === "collapsed" ? setIsExpand(false) : setIsExpand(true);
  }, [infobarType]);

  const handleClick = (buttonIndex) => {
    isExpand && setSelectedButton(buttonIndex);
  };

  // handle info bar expand and collapse
  const handleExpand = () => {
    setSelectedButton(null);
    toggleInfobarCallback();
  };

  // handle button click styles
  const buttonStyles = (index) => {
    if (index === 8) {
      return {
        ...styles.btnExpanded,
        backgroundColor: selectedButton === index ? "#005C8E" : "#FFFFFF",
        color: selectedButton === index ? "#FFFFFF" : "#1E1E1E",
      };
    }
    return {
      ...styles.btnExpanded,
      backgroundColor: selectedButton === index ? "#005C8E" : "#FFFFFF",
      color: selectedButton === index ? "#FFFFFF" : "#7E7E7E",
    };
  };

  const clientInfoTextBtn = (index) => ({
    ...styles.clientInfo,
    color: selectedButton === index ? "#FFFFFF" : "#1E1E1E",
  });

  const parameterInfoTextBtn = (index) => ({
    ...styles.parameterInfoExpanded,
    color: selectedButton === index ? "#FFFFFF" : "#7E7E7E",
  });

  // Add event listener for clicks outside on component mount
  useEffect(() => {
    // Add event listener when the component mounts
    document.addEventListener("click", handleClickOutside);
    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const [selectedOption, setSelectedOption] = useState("");
  console.log("selectedOption selectedOption: ", selectedOption);

  return (
    <div
      style={{
        ...styles.infoContainer,
        gap: isExpand ? "0.25rem" : "0",
        height: isExpand ? "78pt" : "40pt",
      }}
    >
      {isExpand ? (
        <>
          <div style={{ ...styles.commonContainer, ...styles.firstContainer }}>
            <img src={expoMart} alt="img" style={styles.imgIcon} />

            <button
              style={buttonStyles(0)}
              onClick={() => {
                handleClick(0);
              }}
            >
              <span style={styles.clientHeadingText}>Client</span>
              <span style={clientInfoTextBtn(0)}>Elecrama</span>
            </button>
            <button style={buttonStyles(1)} onClick={() => handleClick(1)}>
              <span style={styles.clientHeadingText}>Project</span>
              <span style={clientInfoTextBtn(1)}>India Expo & Mart</span>
            </button>
            <button style={buttonStyles(2)} onClick={() => handleClick(2)}>
              <span style={styles.clientHeadingText}>City</span>
              <span style={clientInfoTextBtn(2)}>Noida, UP</span>
            </button>
            <button style={buttonStyles(3)} onClick={() => handleClick(3)}>
              <span style={styles.clientHeadingText}>Work Site Name</span>
              <span style={clientInfoTextBtn(3)}>Knowledge Park II</span>
            </button>
            <img src={setting} alt="img" />
            {/* <i className="ri-more-2-line"></i> */}
          </div>
          <div>
            <img
              // src={theme ? arrowUpLight : arrowUp}
              src={arrowUp}
              alt="img"
              style={styles.arrowIcon}
              onClick={handleExpand}
            />
          </div>
          <div style={{ ...styles.commonContainer, ...styles.middleContainer }}>
            <button style={buttonStyles(4)} onClick={() => handleClick(4)}>
              <span style={styles.parameterHeadingExpanded}>
                Sessions
              </span>
              <span style={parameterInfoTextBtn(4)}>3,210</span>
            </button>
            <button style={buttonStyles(5)} onClick={() => handleClick(5)}>
              <span style={styles.parameterHeadingExpanded}>
               Units
              </span>
              <span style={parameterInfoTextBtn(5)}>14,460</span>
            </button>
            <button style={buttonStyles(6)} onClick={() => handleClick(6)}>
              <span style={styles.parameterHeadingExpanded}>
                CO<sub>2</sub> savings
              </span>
              <span style={parameterInfoTextBtn(6)}>11,513</span>
            </button>
            <button
              style={buttonStyles(7)}
              onClick={() => {
                handleClick(7)
              }
              }
            >
              <span style={styles.parameterHeadingExpanded}>
              Revenue
              </span>
              <span style={styles.parameterHeadingExpanded}>
                ₹ <span style={parameterInfoTextBtn(7)}>2,16,900</span>
              </span>
            </button>
            <button
              ref={buttonRef}
              style={{
                ...buttonStyles(8),
                padding: "0 10px",
                fontSize: "25px",
              }}
              onClick={(event) => {
                // event.stopPropagation();
                handleClick(8);
                setIsDropdown(!isDropdown);
                setSelectedOption("");
              }}
            >
              <i className="ri-menu-line"></i>
            </button>
            {/* Filter Dashboard based on selected date range and location */}
            {isDropdown && (
              <>
                <div ref={dropdownRef} style={styles.dropdownContainer}>
                  <DropDown
                    options={dashboardData}
                    selectedOption={selectedOption}
                    onSelect={(optionSelected) =>
                      setSelectedOption(optionSelected)
                    }
                  />
                  {selectedOption?.name === "Calendar" && (
                    <div style={styles.calendarContainer}>
                      <DropDown
                        options={dateRangeData}
                        selectedOption={selectedDateRange}
                        onSelect={(optionSelected) => {
                          setSelectedDateRange(optionSelected);
                          optionSelected?.value !== "dateRange" &&
                            handleDateRange(optionSelected?.value);
                        }}
                      />

                      {selectedDateRange?.value === "dateRange" && (
                        <div
                          style={{
                            position: "absolute",
                            top: "261px",
                            right: "148px",
                            display: "flex",
                          }}
                          ref={calendarRef}
                        >
                          <ReactFlatpickr
                            id="date-range"
                            options={{
                              minDate: "2024-01-01",
                              maxDate: new Date(),
                              dateFormat: "Y-m-d",
                            }}
                            value={startDate}
                            style={styles.datePickerCustom}
                            placeholder="Start date"
                            onChange={handleStartDateChange}
                            ref={startRef}
                          />
                          <ReactFlatpickr
                            id="date-range"
                            options={{
                              minDate: startDate ? startDate : "2024-01-01",
                              maxDate: new Date(),
                              dateFormat: "Y-m-d",
                            }}
                            value={endDate}
                            style={styles.datePickerCustom}
                            placeholder="End date"
                            onChange={handleEndDateChange}
                            ref={endRef}
                          />
                        </div>
                      )}
                    </div>
                  )}
                  {selectedOption?.name === "Region" && (
                    <div style={styles.locationContainer}>
                      <DropDown
                        options={locationData}
                        selectedOption={selectedLocation}
                        onSelect={(optionSelected) => {
                          setSelectedLocation(optionSelected);
                          changeLocation(optionSelected?.name);
                        }}
                      />
                    </div>
                  )}
                  {selectedOption?.name === "Projects" && (
                    <div style={styles.projectContainer}>
                      <DropDown
                        options={projectData}
                        selectedOption={selectedProject}
                        onSelect={(optionSelected) => {
                          setSelectedProject(optionSelected);
                          changeProject(optionSelected?.name);
                        }}
                      />
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
          <div style={{ ...styles.commonContainer, ...styles.lastContainer }}>
            <img src={menu2} alt="img" />
            <img src={bell} alt="img" />
          </div>
        </>
      ) : (
        <>
          <div style={styles.firstContainerCollapsed}>
            <span style={{ ...styles.clientInfoText, color: "#7E7E7E" }}>
              Client Info
            </span>
            <span>Elecrama</span>
            <span>India Expo & Mart</span>
            <span>Noida, UP</span>
            <span>Knowledge Park II</span>
          </div>
          <div>
            <img
              src={arrowDown}
              alt="img"
              style={styles.arrowIcon}
              onClick={handleExpand}
            />
          </div>
          <div style={styles.secondContainerCollapsed}>
            <button style={styles.btn}>
              <span style={styles.parameterHeading}>Sessions</span>
              <span style={styles.parameterInfo}>3,210</span>
            </button>
            <button style={styles.btn}>
              <span style={styles.parameterHeading}>Units</span>
              <span style={styles.parameterInfo}>14,460</span>
            </button>
            <button style={styles.btn}>
              <span style={styles.parameterHeading}>CO<sub>2</sub> savings</span>
              <span style={styles.parameterInfo}>11,513</span>
            </button>
            <button style={styles.btn}>
              <span style={styles.parameterHeading}>Revenue:</span>
              <span style={styles.parameterInfo}>
                ₹ <span style={styles.parameterInfo}>2,16,900</span>
              </span>
            </button>
          </div>
        </>
      )}
    </div>
  );
};
// Styling
const styles = {
  infoContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  commonContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    border: `1.5pt solid #008CD7`,
    borderBottom: `1.5pt solid #008CD7`,
  },
  firstContainer: {
    width: "46.9vw",
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
    borderTopLeftRadius: "3.125rem",
    borderBottomLeftRadius: "3.125rem",
  },
  firstContainerCollapsed: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    border: "none",
    borderBottom: `1.6pt solid #008CD7`,
    width: "48vw",
    paddingLeft: "0.5rem",
    paddingRight: "2.5rem",
    borderTopLeftRadius: "0",
    borderBottomLeftRadius: "0",
  },
  secondContainerCollapsed: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    border: "none",
    borderBottom: `1.6pt solid #008CD7`,
    width: "52vw",
    padding: "0 0.5rem",
  },
  middleContainer: {
    width: "46.9vw",
    padding: "0 0.15rem",
  },
  lastContainer: {
    justifyContent: "space-around",
    width: "10.5vw",
    borderTopRightRadius: "3.125rem",
    borderBottomRightRadius: "3.125rem",
  },
  imgIcon: {
    width: "4.6875rem",
    height: "4.6875rem",
    border: `1.5pt solid #008CD7`,
    borderRadius: "50%",
  },
  arrowIcon: {
    position: "absolute",
    bottom: "-1.875rem",
    border: `1.5pt solid #008CD7`,
    borderRadius: "50%",
    padding: "0.3125rem",
    transform: "translate(-50%,-50%)",
    backgroundColor: "#FFFFFF",
    cursor: "pointer",
    zIndex: "10",
  },
  clientHeadingText: {
    fontSize: "11pt",
    display: "block",
  },
  parameterHeadingExpanded: {
    fontSize: "10pt",
    fontWeight: "500",
  },
  parameterHeading: {
    fontSize: "10pt",
    fontWeight: "500",
  },
  clientInfoText: {
    fontSize: "11pt",
    fontWeight: "400",
  },
  parameterInfo: {
    fontSize: "12pt",
    fontWeight: "bold",
    color: "#1E1E1E",
  },
  parameterInfoExpanded: {
    fontSize: "20pt",
    fontWeight: "400",
  },
  icon: {
    width: "1.875rem",
    height: "1.875rem",
    display: "block",
  },

  btn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "0.5rem",
    border: "none",
    cursor: "pointer",
    height: "35pt",
    padding: "0 0.375rem",
    flexDirection: "row",
    backgroundColor: "#FFFFFF",
    color: "#7E7E7E",
  },
  btnExpanded: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "",
    gap: "0.25rem",
    border: "none",
    cursor: "pointer",
    height: "75.5pt",
    padding: "0 0.375rem",
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    color: "#7E7E7E",
  },
  dropdownContainer: {
    position: "absolute",
    top: "123px",
    right: "126px",
    backgroundColor: "white",
    width: "120px",
  },
  dropDownSelect: {
    padding: "5px 20px 5px 10px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    gap: "4px",
  },
  locationContainer: {
    position: "absolute",
    right: "120px",
    top: "44px",
    backgroundColor: "#ffffff",
    borderRadius: "4px",
    width: "120px",
  },
  projectContainer: {
    position: "absolute",
    right: "120px",
    top: "88px",
    width: "120px",
    backgroundColor: "#ffffff",
    borderRadius: "4px",
  },
  locationSelect: {
    padding: "5px 20px 5px 10px",
    cursor: "pointer",
  },
  calendarContainer: {
    position: "absolute",
    right: "120px",
    top: "10px",
    width: "150px",
    backgroundColor: "#ffffff",
    borderRadius: "4px",
  },
  datePicker: {
    width: "200px",
    outline: "none",
    border: `1.5pt solid #008CD7`,
    height: "30px",
    borderRadius: "4px",
  },
  datePickerCustom: {
    width: "100px",
    outline: "none",
    border: `1.5pt solid #008CD7`,
    height: "56px",
    borderRadius: "4px",
  },
};
const mapStatetoProps = (state) => {
  const { infobarType } = state.Layout;
  const {dateRange}=state.dashboardFilter;
  return { infobarType,dateRange };
};
const mapDispatchToProps = (dispatch) => ({
  changeDateRange: (payload) => dispatch(changeDateRange(payload)),
  changeLocation: (payload) => dispatch(changeLocation(payload)),
  changeProject: (payload) => dispatch(changeProject(payload)),
});

export default connect(mapStatetoProps, mapDispatchToProps)(InfoBar);
