import React, { Component } from "react";
import {
  Card,
  CardBody,
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//Import Charts
import ReactApexChart from "react-apexcharts";
import "./dashboard.scss";
import { lkprimary, lkltblue, lkgreen } from "./styles";

class EarningReports extends Component {
  state = {
    menu: false,
    series: [72],
    options: {
      chart: {
        sparkline: {
          enabled: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      colors: [lkprimary],
      stroke: {
        lineCap: "round",
      },
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 0,
            size: "70%",
          },
          track: {
            margin: 0,
          },

          dataLabels: {
            show: false,
          },
        },
      },
    },
    series2: [65],
    options2: {
      chart: {
        sparkline: {
          enabled: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      colors: [lkgreen],
      stroke: {
        lineCap: "round",
      },
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 0,
            size: "70%",
          },
          track: {
            margin: 0,
          },

          dataLabels: {
            show: false,
          },
        },
      },
    },
  };
  render() {
    return (
      <React.Fragment>
        <Card>
          <CardBody>
            <Dropdown
              className="float-right"
              isOpen={this.state.menu}
              toggle={() => this.setState({ menu: !this.state.menu })}
            >
              <DropdownToggle tag="i" className="arrow-none card-drop">
                <i className="mdi mdi-dots-vertical"></i>
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem href="">Sales Report</DropdownItem>

                <DropdownItem href="">Export Report</DropdownItem>

                <DropdownItem href="">Profit</DropdownItem>

                <DropdownItem href="">Action</DropdownItem>
              </DropdownMenu>
            </Dropdown>

            <h4 className="card-title mb-2 ml-2">Environmental Impact</h4>
            <div className="text-center">
              <Row>
                <Col sm={7}>
                  <div>
                    {/* <div className="mb-3">
                      <div id="radialchart-1" className="apex-charts">
                        <ReactApexChart
                          options={this.state.options}
                          series={this.state.series}
                          type="radialBar"
                          height={this.props.ht}
                        />
                      </div>
                    </div> */}

                    <p className="text-muted text-start">
                      Charging units consumed in kWh
                    </p>
                    <div className="d-flex justify-content-center align-items-center gap-5">
                      <h5 className="mb-0">14,460</h5>
                      {/* <span>kWh</span> */}
                    </div>
                  </div>
                </Col>

                <Col sm={5}>
                  <div className="mt-5 mt-sm-0">
                    {/* <div className="mb-3">
                      <div id="radialchart-2" className="apex-charts"> 
                        <ReactApexChart
                          options={this.state.options2}
                          series={this.state.series2}
                          type="radialBar"
                          height={this.props.ht}
                        />
                      </div> 
                    </div> */}

                    <p className="text-muted text-start ">
                      Carbon Savings in kg
                    </p>

                    <div className="d-flex justify-content-center align-items-center gap-5">
                      <h5 className="mb-0">11,513</h5>
                      {/* <span>kg</span> */}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

export default EarningReports;
