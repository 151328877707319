import React, { Component } from "react";
// import apexChart
import LineApexChart from "../AllCharts/apex/chartapex";
import DashedLine from "../AllCharts/apex/dashedLine";
import SplineArea from "../AllCharts/apex/SplineArea";
import Apaexlinecolumn from "../AllCharts/apex/apaexlinecolumn";
import ColumnWithDataLabels from "../AllCharts/apex/ColumnWithDataLabels";
import BarChart from "../AllCharts/apex/barchart";
import LineColumnArea from "../AllCharts/apex/LineColumnArea";
import RadialChart from "../AllCharts/apex/RadialChart";
import PieChart from "../AllCharts/apex/PieChart";
import DonutChart from "../AllCharts/apex/dountchart";
import Knob from "../AllCharts/knob/knob";

import { Container, Row, Col, Card, CardBody, CardTitle } from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import ReactApexChart from "react-apexcharts";
import { lkgreen, lkred } from "./styles";
import { connect } from "react-redux";
import { activeKnob } from "../Utility/dataSet";

//let chargerId_1 = "1CB705144B8C";
//let chargerId_2 = "2C484CEF49C0";
//let chargerId_2 = "D8478F0C8598";
//let chargerId_3 = "3C1E06144B8C";

let chargerId_1 = "0CED8F1B5AE0";
let chargerId_2 = "D8478F0C85F4";
let chargerId_3 = "3801991B5AE0";
let chargerId_4 = "D8478F0C8598";


class ActiveKnob extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Charts", link: "#" },
        { title: "Apex charts", link: "#" },
      ],
      charger1: "Offline",
      charger2: "Available",
      charger3: "Offline",
      series: [],
      active: 0,
      inactive: 0,
      syncIntervalId: "",
      options: {
        plotOptions: {
          radialBar: {
            dataLabels: {
              name: {
                fontSize: "22px",
              },
              value: {
                fontSize: "16px",
              },
              total: {
                show: true,
                label: "33%",
                formatter: function (w) {
                  // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                  return "";
                },
              },
            },
          },
        },

        //labels: ['Active Session', 'Fault', 'Active Session', 'Fault'],
        // colors: ['#556ee6', '#34c38f', '#f46a6a', '#34c38f'],

        labels: ["Idle/In-active", "Active-session"],
        colors: [lkred, lkgreen],
      },
    };
  }
  filterSeriesOnDate = () => {
    const currentDateRange = this.props.dateRange;
    const startDate = new Date(currentDateRange[0]);
    const endDate = new Date(currentDateRange[1]);
    const filteredData = activeKnob(startDate, endDate);
    // Update state with filtered series
    this.setState({ series: filteredData });
  };
  async componentDidMount() {
    let charger1_status = "";
    let charger2_status = "";
    let charger3_status = "";
    let charger4_status = "";

    try {
      charger1_status = await this.getChargerStatus(chargerId_1);
      charger2_status = await this.getChargerStatus(chargerId_2);
      charger3_status = await this.getChargerStatus(chargerId_3);
    } catch (err) {
      console.log("Error fetching Charger status : ", err);
    }

    const intervalId = setInterval(async () => {
      try {
        charger1_status = await this.getChargerStatus(chargerId_1);
        charger2_status = await this.getChargerStatus(chargerId_2);
        charger3_status = await this.getChargerStatus(chargerId_3);
      } catch (err) {
        console.log("Error fetching Charger status : ", err);
      }

      // Commented put for Elecrama25 as requested by LK Team
      //this.setState({
      //  charger1: charger1_status,
      //  charger2: charger2_status,
      //  charger3: charger3_status,
     // });

      // Commented for Elecrama 25 as requested by LK Team

      //this.updateChargerStats();
    }, 69000);


   // Commented out for Elecrama25 as requested by LK Team

   // this.setState({
   //   charger1: charger1_status,
   //   charger2: charger2_status,
   //   charger3: charger3_status,
   //   syncIntervalId: intervalId,
   // });

    // Commented for Elecrama25 as requested by LK Team

    //this.updateChargerStats();
    this.filterSeriesOnDate();
  }
  componentDidUpdate(prevProps) {
    const prevDateRange = prevProps.dateRange;
    const currentDateRange = this.props.dateRange;

    // Compare the actual date values
    const prevYear1 = prevDateRange ? prevDateRange[0]?.getTime() : null;
    const prevYear2 = prevDateRange ? prevDateRange[1]?.getTime() : null;
    const currentYear1 = currentDateRange
      ? currentDateRange[0]?.getTime()
      : null;
    const currentYear2 = currentDateRange
      ? currentDateRange[1]?.getTime()
      : null;

    // Check if the date range or location has changed
    if (
      prevYear1 !== currentYear1 ||
      prevYear2 !== currentYear2 ||
      prevProps.location !== this.props.location
    ) {
      this.setState({
        dateRange: currentDateRange,
        location: this.props.location,
      });

      this.filterSeriesOnDate();
    }
  }
  componentWillUnmount() {
    clearInterval(this.state.syncIntervalId);
  }

  getChargerStatus = async (chargerId) => {
    if (chargerId == null) return;

    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      /*    body: JSON.stringify({
                   token: "8lQzN922rpRDaMbh3m5J",
                   id: "#"
               }) */
    };
    try {
      const response = await fetch(
        "https://backend.iqlabs.io/api/evChargers/statusLogs/recent?token=8lQzN922rpRDaMbh3m5J&id=" +
          chargerId,
        requestOptions
      );
      const responseJson = await response.json();
      //console.log("Fetch data Reponse", responseJson);
      const data = responseJson.data;
      if (data.length <= 0) return;

      //console.log("Fetch data Reponse", data.at(0));
      return data.at(0).status;
    } catch (err) {
      console.log("Error fetching data: ", err);
    }
  };

  updateChargerStats = () => {
    let active_ch = 0;
    let inactive_ch = 0;
    let offline_ch = 0;

    console.log("Charger 1 Stats", this.state.charger1);
    console.log("Charger 2 Stats", this.state.charger2);
    console.log("Charger 3 Stats", this.state.charger3);

    if (!this.state.charger1) {
      //inactive_ch++;
      offline_ch++;
    } else if (this.state.charger1 === "Faulted") inactive_ch++;
    else if (this.state.charger1 === "Offline") {
      offline_ch++;
    } else active_ch++;

    if (!this.state.charger2) {
      //inactive_ch++;
      offline_ch++;
    }
    //Charger 2
    else if (this.state.charger2 === "Faulted") inactive_ch++;
    else if (this.state.charger2 === "Offline") {
      offline_ch++;
    } else active_ch++;

    if (!this.state.charger3) {
      // inactive_ch++;
      offline_ch++;
    }
    //Charger 3
    else if (this.state.charger3 === "Faulted") inactive_ch++;
    else if (this.state.charger3 === "Offline") {
      offline_ch++;
    } else active_ch++;

    const stats = [
      (((offline_ch + inactive_ch) * 100) / 3).toFixed(2),
      ((active_ch * 100) / 3).toFixed(2),
    ];

    this.setState({
      active: active_ch,
      inactive: inactive_ch + offline_ch,
      series: stats,
    });
  };

  render() {
    return (
      <React.Fragment>
        <Card>
          <CardBody>
            <div className="float-right">
              {false && (
                <select className="custom-select custom-select-sm">
                  <option value="1">Jan</option>
                  <option defaultValue>Feb</option>
                  <option value="2">Mar</option>
                  <option value="3">Apr</option>
                  <option value="4">May</option>
                  <option value="5">Jun</option>
                  <option value="6">Jul</option>
                  <option value="7">Aug</option>
                  <option value="8">Sep</option>
                  <option value="9">Oct</option>
                  <option value="10">Nov</option>
                  <option value="11">Dec</option>
                </select>
              )}
            </div>
            <CardTitle className="mb-4">Utilization rate</CardTitle>

            <div className="text-center" dir="ltr">
              <ReactApexChart
                options={this.state.options}
                series={this.state.series}
                type="radialBar"
                height="380"
              />
            </div>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  dateRange: state.dashboardFilter.dateRange,
  location: state.dashboardFilter.location,
});

export default connect(mapStateToProps)(ActiveKnob);
