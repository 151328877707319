export const locationData = [
  { name: "Bengaluru" },
  { name: "Mysuru" },
  { name: "Mumbai" },
  { name: "Hyderabad" },
  { name: "Delhi" },
  { name: "Chennai" },
];
export const projectData = [
  { name: "Project 1" },
  { name: "Project 2" },
  { name: "Project 3" },
];
export const dashboardData = [
  {
    name: "Calendar",
    icon: "ri-calendar-line",
  },
  {
    name: "Region",
    icon: "ri-map-pin-2-line",
  },
  {
    name: "Projects",
    icon: "ri-folder-line",
  },
];
export const dateRangeData = [
  {
    name: "Today",
    value: "today",
  },
  {
    name: "Yesterday",
    value: "yesterday",
  },
  {
    name: "Last 7 Days",
    value: "day7",
  },
  {
    name: "Last 30 Days",
    value: "day30",
  },
  {
    name: "This Month",
    value: "thisMonth",
  },
  {
    name: "Last Month",
    value: "lastMonth",
  },
  {
    name: "Custom Date Range",
    value: "dateRange",
    icon: "ri-add-line",
  },
];