import React, { Component } from "react";
import { Card, CardBody, Row, Col } from "reactstrap";

//Import Charts
import ReactApexChart from "react-apexcharts";
import "./dashboard.scss";
import { connect } from "react-redux";
import { salesAnalytics } from "../Utility/dataSet";

class SalesAnalytics extends Component {
  state = {
    series: [45, 35, 20],
    donutOptions: {
      labels: ["Four-wheeler", "Three-wheeler", "Two-wheeler"],
      plotOptions: {
        donut: {
          donut: {
            size: "66.66%",
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      colors: ["#005C8E", "#00943D", "#EE8C19"],
    },
    pieOptions: {
      labels: ["Four-wheeler", "Three-wheeler", "Two-wheeler"],
      plotOptions: {
        pie: {
          pie: {
            size: "45%",
          },
        },
      },
      customScale: 0.9,
      dataLabels: {
        enabled: true,
        style: {
          color: ["#EFEFEF"],
        },
      },

      legend: {
        show: false,
      },
      // colors: ["#88B6FB", "#85CCA2", "#F7C891"],
      colors: [
        "rgba(136, 182, 251, 0.6)",
        "rgba(133, 204, 162, 0.6)",
        "rgba(247, 200, 145, 0.6)",
      ],
    },
  };
   constructor(props) {
      super(props);
  
      this.state = {
        dateRange: props.dateRange,
        location: props.location,
        series: [],
        donutOptions: {
          labels: ["Four-wheeler", "Three-wheeler", "Two-wheeler"],
          plotOptions: {
            donut: {
              donut: {
                size: "66.66%",
              },
            },
          },
          dataLabels: {
            enabled: false,
          },
          legend: {
            show: false,
          },
          colors: ["#005C8E", "#00943D", "#EE8C19"],
        },
        pieOptions: {
          labels: ["Four-wheeler", "Three-wheeler", "Two-wheeler"],
          plotOptions: {
            pie: {
              pie: {
                size: "45%",
              },
            },
          },
          customScale: 0.9,
          dataLabels: {
            enabled: true,
            style: {
              color: ["#EFEFEF"],
            },
          },
    
          legend: {
            show: false,
          },
          // colors: ["#88B6FB", "#85CCA2", "#F7C891"],
          colors: [
            "rgba(136, 182, 251, 0.6)",
            "rgba(133, 204, 162, 0.6)",
            "rgba(247, 200, 145, 0.6)",
          ],
        },
      };
    }
  
    filterSeriesOnDate = () => {
      const currentDateRange = this.props.dateRange;
      const startDate = new Date(currentDateRange[0]);
      const endDate = new Date(currentDateRange[1]);
      const filteredData = salesAnalytics(startDate, endDate);
      // Update state with filtered series
      this.setState({ series: filteredData });
    };
  
    componentDidMount() {
      this.filterSeriesOnDate();
    }
  
    componentDidUpdate(prevProps) {
      const prevDateRange = prevProps.dateRange;
      const currentDateRange = this.props.dateRange;
  
      // Compare the actual date values
      const prevYear1 = prevDateRange ? prevDateRange[0]?.getTime() : null;
      const prevYear2 = prevDateRange ? prevDateRange[1]?.getTime() : null;
      const currentYear1 = currentDateRange
        ? currentDateRange[0]?.getTime()
        : null;
      const currentYear2 = currentDateRange
        ? currentDateRange[1]?.getTime()
        : null;
  
      // Check if the date range or location has changed
      if (
        prevYear1 !== currentYear1 ||
        prevYear2 !== currentYear2 ||
        prevProps.location !== this.props.location
      ) {
        this.setState({
          dateRange: currentDateRange,
          location: this.props.location,
        });
  
        this.filterSeriesOnDate();
      }
    }
  render() {
    return (
      <React.Fragment>
        <Card>
          <CardBody>
            {/* <div className="float-right">
              <select className="custom-select custom-select-sm">
                <option defaultValue="5">May</option>
                <option value="1">Jan</option>
                <option value="2">Feb</option>
                <option value="3">Mar</option>
                <option value="4">Apr</option>
                <option defaultValue="5">May</option>
                <option value="6">Jun</option>
                <option value="7">July</option>
                <option value="8">Aug</option>
                <option value="9">Sep</option>
                <option value="10">Oct</option>
                <option value="11">Nov</option>
                <option value="12">Dec</option>
              </select>
            </div> */}
            <h4 className="card-title mb-4">Utilization Analytics</h4>

            <div id="donut-chart" className="apex-charts">
              <div className="d-flex justify-content-center">
                {/* Donut Chart */}
                <div
                  style={{
                    //  position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'
                    position: "relative",
                    width: "360px",
                    height: "360px",
                  }}
                >
                  <ReactApexChart
                    options={this.state.donutOptions}
                    series={this.state.series}
                    type="donut"
                    height="330"
                  />
                </div>

                {/* Pie Chart */}
                <div
                  style={{
                    position: "absolute",
                    top: "24.4%",
                    left: "10.10%",
                    width: "80%",
                    height: "80%",
                    // height:"200",
                    // left:"11.6%",
                    // top:"25.45%",
                  }}
                >
                  <ReactApexChart
                    options={this.state.pieOptions}
                    series={this.state.series}
                    type="pie"
                    height="225"
                  />
                </div>
              </div>
            </div>

            <Row>
              <Col xs={4}>
                <div className="text-center ">
                  <p className="mb-2 text-truncate">
                    <i className="mdi mdi-circle text-primary font-size-10 mr-1"></i>{" "}
                    Four-wheeler
                  </p>
                  {/* <h5>45 %</h5> */}
                </div>
              </Col>
              <Col xs={4}>
                <div className="text-center ">
                  <p className="mb-2 text-truncate">
                    <i className="mdi mdi-circle text-success font-size-10 mr-1"></i>{" "}
                    Three-wheeler
                  </p>
                  {/* <h5>35 %</h5> */}
                </div>
              </Col>
              <Col xs={4}>
                <div className="text-center">
                  <p className="mb-2 text-truncate">
                    <i className="mdi mdi-circle text-warning font-size-10 mr-1"></i>{" "}
                    Two-wheeler
                  </p>
                  {/* <h5>20 %</h5> */}
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  dateRange: state.dashboardFilter.dateRange,
  location: state.dashboardFilter.location,
});

export default connect(mapStateToProps)(SalesAnalytics);


