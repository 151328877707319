import React, { Component, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//Import Components
import MiniWidgets from "./MiniWidgets";
import RevenueAnalytics from "./RevenueAnalytics";
import SalesAnalytics from "./SalesAnalytics";
import EarningReports from "./EarningReports";
import Sources from "./Sources";
import RecentlyActivity from "./RecentlyActivity";
import RevenueByLocations from "./RevenueByLocations";
import FaultTrend from "./FaultTrend";
import FaultAnalytics from "./FaultAnalytics";
import FaultBar from "./FaultBar";
import ConnectivityStats from "./ConnectivityStats";
import ActiveKnob from "./ActiveKnob";
import ChatBox from "./ChatBox";
import LatestTransactions from "./LatestTransactions";
import axios from "axios";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { isTechnician } from "../Authentication/userMetaData";
import store from "../../store";
import { lkred } from "./styles";
import InfoBar from "./InfoBar";
import { toggleInfobar } from "../../store/actions";
/* let trans_data = [];

const getDate = (isodate) => {
    if (isodate == null)
        return "";

    const date = new Date(isodate);
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear().toString();

    if (day < 10)
        day = "0" + day.toString();

    if (month < 10)
        month = "0" + month.toString();

    return (day + "-" + month + "-" + year);
}


const updateTrasactionLog = () => {
    Axios.get("https://backend.iqlabs.io/api/evChargers/transactions", {
        params: {
            token: "8lQzN922rpRDaMbh3m5J",
            id: "#"
        }
    })
        .then((res) => {



            let i = 0;
            res.data.data.reverse().forEach((item, index) => {
                trans_data.push({
                    id: ++i,
                    conn: item.connectorId,
                    orderId: <Link to="#" className="text-dark font-weight-bold">{item.chargerId}</Link>,
                    date: getDate(item.startTime),
                    billingName: item.userId,
                    total: "\u20B9" + ((parseInt(item.meterStop, 10) - parseInt(item.meterStart, 10)) / 1000 * 10).toString(),
                    consumption: ((parseInt(item.meterStop, 10) - parseInt(item.meterStart, 10)) / 1000).toString(),
                    status: <div className="badge badge-soft-success font-size-12">Paid</div>,

                })
            });

            //console.log("Transactions", trans_data);

        });
}

const updateLogAsync = async () => {
    try {
        const res = await Axios.get("https://backend.iqlabs.io/api/evChargers/transactions", {
            params: {
                token: "8lQzN922rpRDaMbh3m5J",
                id: "#"
            }
        });

        const data = await res.data;
        // console.log("Transaction Log Update", data);
        let i = 0;
        data.data.reverse().forEach((item, index) => {
            trans_data.push({
                id: ++i,
                conn: item.connectorId,
                orderId: <Link to="#" className="text-dark font-weight-bold">{item.chargerId}</Link>,
                date: getDate(item.startTime),
                billingName: item.userId,
                total: "\u20B9" + ((parseInt(item.meterStop, 10) - parseInt(item.meterStart, 10)) / 1000 * 10).toString(),
                consumption: ((parseInt(item.meterStop, 10) - parseInt(item.meterStart, 10)) / 1000).toString(),
                status: <div className="badge badge-soft-success font-size-12">Paid</div>,

            })
        });
    }
    catch (errors) {
        console.log("Transaction Log Update Errors", errors);
    }
};

updateLogAsync(); */

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Energy Dash", link: "#" },
        { title: "Dashboard", link: "#" },
      ],
      reports: [
        {
          icon: "ri-stack-line",
          title: "Number of Sessions",
          value: "3,210",
          rate: "12.33%",
          desc: "From last Month",
          rateChange: "up",
        },
        {
          icon: "ri-briefcase-4-line",
          title: "Total Revenue",
          value: "\u20B92,16,900",
          rate: "3.43%",
          desc: "From last Month",
          rateChange: "down",
        },
        {
          icon: "ri-currency-line",
          title: "Avg. Revenue per Session",
          value: "\u20B9 67.57",
          rate: "32.4%",
          desc: "From previous period",
          rateChange: "up",
        },
      ],

      faultReports: [
        {
          icon: "ri-charging-pile-2-fill",
          title: "Number of Chargers",
          value: "3",
          rate: "12.33%",
          desc: "From last Month",
          rateChange: "up",
        },
        {
          icon: "ri-arrow-down-circle-fill",
          title: "Total Downtime (hrs)",
          value: "10",
          rate: "10.59%",
          desc: "From last Month",
          rateChange: "up",
        },
        {
          icon: "ri-error-warning-fill",
          title: "Number of Faults",
          value: "13",
          rate: "32.4%",
          desc: "From previous period",
          rateChange: "up",
        },
      ],

      latestTrans: [],

      transUpdateIntervalId: "",

      user: props.user,
      // dateRange: props.dateRange,
      // location: props.location,
    };
  }

  computeTime = (isodate) => {
    if (isodate == null) return "";

    const date = new Date(isodate);

    let hour = date.getHours().toString();
    let mins = date.getMinutes().toString();
    let secs = date.getSeconds().toString();

    if (hour.length < 2) hour = "0" + hour;

    if (mins.length < 2) mins = "0" + mins;

    if (secs.length < 2) secs = "0" + secs;

    return hour + ":" + mins + ":" + secs;
  };

  computeDate = (isodate) => {
    if (isodate == null) return "";

    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const date = new Date(isodate);
    let day = date.getDate();
    let month = date.getMonth();
    let year = date.getFullYear().toString();

    if (day < 10) day = "0" + day.toString();

    if (month < 10) month = "0" + month.toString();

    return day + " " + months.at(month) + ", " + year;
  };

  computeConsumption = (starMeterVal, stopMeterVal) => {
    if (isNaN(starMeterVal)) return "";

    if (isNaN(stopMeterVal))
      return (
        <div className="badge badge-soft-warning font-size-12">On-going</div>
      );

    return (
      (parseInt(stopMeterVal, 10) - parseInt(starMeterVal, 10)) /
      1000
    ).toString();
  };

  computeTotal = (starMeterVal, stopMeterVal) => {
    if (isNaN(stopMeterVal))
      return (
        <div className="badge badge-soft-warning font-size-12">On-going</div>
      );

    return (
      "\u20B9" +
      (
        ((parseInt(stopMeterVal, 10) - parseInt(starMeterVal, 10)) * 10) /
        1000
      ).toString()
    );
  };

  computePaymentStatus = (starMeterVal, stopMeterVal) => {
    if (isNaN(stopMeterVal))
      return <div className="badge badge-soft-warning font-size-12">Paid</div>;

    return <div className="badge badge-soft-success font-size-12">Paid</div>;
  };

  async componentDidMount() {
    /*  var intervalId = setInterval(this.updateLogAsync.bind(this), 30000);
         this.setState({ transUpdateIntervalId: intervalId });
         updateLogAsync(); */

    // console.log("Store", store.getState());
    try {
      await this.updateTrasactionLog();
    } catch (e) {
      console.log("Main Dash Update Error", e.toString());
    }

    const intervalId = setInterval(async () => {
      try {
        await this.updateTrasactionLog();
      } catch (e) {
        console.log("Main Dash Peiodic Update Error", e.toString());
      }
    }, 5000);

    this.setState({ transUpdateIntervalId: intervalId });
  }

  componentWillUnmount() {
    /*  if (this.state.transUpdateIntervalId != -1)
             clearInterval(this.state.transUpdateIntervalId); */

    if (this.state.transUpdateIntervalId !== null)
      clearInterval(this.state.transUpdateIntervalId);
  }

  updateTrasactionLog1 = () => {
    axios
      .get(
        "https://backend.iqlabs.io/api/evChargers/transactions?token=8lQzN922rpRDaMbh3m5J&id=F8474CEF49C0",
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log("Axios get Response", res);

        //console.log("Transactions", trans_data);
      })
      .catch((err) => {
        console.log("Trans Update Error", err);
      })
      .finally((res) => {
        console.log("Trans Update Final", res);
      });
  };
  updateTrasactionLog = async () => {
    /*  axios.get("https://backend.iqlabs.io/api/evChargers/transactions", {
            data: {
                token: "8lQzN922rpRDaMbh3m5J",
                id: "#"
            },
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then((res) => {


                const trans_data = [];
                let i = 0;
                res.data.data.reverse().forEach((item, index) => {
                    trans_data.push({
                        id: ++i,
                        conn: item.connectorId,
                        orderId: <Link to="#" className="text-dark font-weight-bold">{item.chargerId}</Link>,
                        date: this.getDate(item.startTime),
                        billingName: item.userId,
                        total: "\u20B9" + ((parseInt(item.meterStop, 10) - parseInt(item.meterStart, 10)) / 1000 * 10).toString(),
                        consumption: ((parseInt(item.meterStop, 10) - parseInt(item.meterStart, 10)) / 1000).toString(),
                        status: <div className="badge badge-soft-success font-size-12">Paid</div>,

                    })
                });

                this.setState({ latestTrans: trans_data });

                //console.log("Transactions", trans_data);

            })
            .catch((err) => { console.log("Trans Update Error", err) })
            .finally((res) => { console.log("Trans Update Final", res) });  */

    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      /*    body: JSON.stringify({
                   token: "8lQzN922rpRDaMbh3m5J",
                   id: "#"
               }) */
    };
    const response = await fetch(
      "https://backend.iqlabs.io/api/evChargers/transactions/byOwnerId/?token=8lQzN922rpRDaMbh3m5J&ownerId=9847040400&limit=100&page=1",
      requestOptions
    );
    const responseJson = await response.json();
    // console.log("Fetch data Reponse", responseJson);
    const data = responseJson.allTransactions;
    //  console.log("Fetch data Reponse data", data);

    let i = 0,
      trans_data = [];
    //Sort by StartTime
    data.sort((a, b) => -a.startTime.localeCompare(b.startTime));

    data.forEach((item) => {
      trans_data.push({
        id: ++i,
        conn: item.connectorId,
        orderId: (
          <Link to="#" className="text-dark font-weight-bold">
            {item.chargerId}
          </Link>
        ),
        date:
          this.computeDate(item.startTime) +
          ", " +
          this.computeTime(item.startTime),
        billingName: item.userId,
        total: this.computeTotal(
          parseInt(item.meterStart, 10),
          parseInt(item.meterStop, 10)
        ),
        consumption: this.computeConsumption(
          parseInt(item.meterStart, 10),
          parseInt(item.meterStop, 10)
        ),
        status: this.computePaymentStatus(
          parseInt(item.meterStart, 10),
          parseInt(item.meterStop, 10)
        ),
        transactionId: item.transactionId,
      });
    });

    if (JSON.stringify(trans_data) === JSON.stringify(this.state.latestTrans))
      return;

    this.setState({ latestTrans: trans_data });
  };
  toggleInfobarCallback = () => {
    if (this.props.infobarType === "collapsed") {
      this.props.toggleInfobar("expanded");
    } else if (this.props.infobarType === "expanded") {
      this.props.toggleInfobar("collapsed");
    }
  };
  render() {
    //this.updateTrasactionLog();
    //console.log("Store", store.getState());
    return (
      <React.Fragment>
        <div
          className={`page-content ${
            !isTechnician(this.state.user) && "dashboard-infobar"
          }`}
        >
          <Container fluid>
            {this.props.layoutType === "vertical" &&
              !isTechnician(this.state.user) && (
                <Row id="page-infobar">
                  <InfoBar toggleInfobarCallback={this.toggleInfobarCallback} />
                </Row>
              )}

            <Breadcrumbs
              title="Dashboard"
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Row>
              <Col xl={8}>
                {!isTechnician(this.state.user) && (
                  <Row>
                    <MiniWidgets reports={this.state.reports} />
                  </Row>
                )}

                {/* revenue Analytics */}
                {!isTechnician(this.state.user) && <RevenueAnalytics />}
                {isTechnician(this.state.user) && <FaultTrend />}
                {isTechnician(this.state.user) && (
                  <Row>
                    <MiniWidgets
                      reports={this.state.faultReports}
                      clr={lkred}
                    />
                  </Row>
                )}
              </Col>
              {!isTechnician(this.state.user) && (
                <Col xl={4}>
                  {/* earning reports */}
                  <EarningReports ht="11" />
                  {/* sales Analytics */}
                  <SalesAnalytics />
                </Col>
              )}

              {isTechnician(this.state.user) && (
                <Col xl={4}>
                  {/* earning reports */}
                  {<ConnectivityStats ht="40" />}

                  {/* Fault Analytics */}
                  <FaultAnalytics />

                  {/* earning reports */}
                  {/* <EarningReports /> */}
                </Col>
              )}
            </Row>

            <Row>
              <Col lg={6}>
                <FaultBar />
              </Col>

              <Col lg={6}>
                <ActiveKnob />
              </Col>
            </Row>

            {!isTechnician(this.state.user) && (
              <Row>
                {/* sources */}

                <Sources />

                {/* recent activity */}
                <RecentlyActivity />

                {/* revenue by locations */}
                <RevenueByLocations />
              </Row>
            )}

            <Row>
              {/* chat box */}
              {/*<ChatBox />*/}

              {/* latest transactions */}
              <LatestTransactions transaction_data={this.state.latestTrans} />
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.Login.user,
  infobarType: state.Layout.infobarType,
  layoutType: state.Layout.layoutType,
});

export default connect(mapStateToProps, { toggleInfobar })(Dashboard);
//export default Dashboard;
